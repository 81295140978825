import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  map,
  tap,
  shareReplay,
  catchError,
  share,
  startWith,
} from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { TripAttributes } from '~app/models';
import { OkayAttributes, OkayType } from '~app/models/okay';
import { TextService, TripService } from '~app/services';
import { CiaoModalComponent } from '../ciao-modal/ciao-modal.component';
import { PaginationResult } from '~app/models/pagination-data';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ToastrService } from 'ngx-toastr';
import { RolePermissionService } from '~app/services/role-permission.service';
import { faHiking } from '@fortawesome/free-solid-svg-icons/faHiking';
import { faMountain } from '@fortawesome/free-solid-svg-icons/faMountain';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { SelectOption } from '../forms/field/types';
import { FormsUtility } from '~app/utilities';
import { CommonModule } from '@angular/common';
import { CiaoSharedModule } from '../shared.module';
import { CiaoFormsModule } from '../forms/forms.module';
import { RouterLink } from '@angular/router';

/** @type {import("@angular/forms").ValidatorFn} */
// This is a temporary solution for handling errors in this form relating to the checkboxes
function ValidatorAtLeastOneSelected(control: AbstractControl<string[]>) {
  const anySelected = control.value?.length > 0;
  if (anySelected) {
    return null;
  } else {
    return {
      noneSelected:
        'Please select at least one crew member to complete check-in.',
    };
  }
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    CiaoSharedModule,
    CiaoFormsModule,
  ],
  selector: 'ciao-okay-modal',
  templateUrl: './okay-modal.component.html',
  styleUrls: ['./okay-modal.component.less'],
})
export class OkayModalComponent implements OnInit {
  @Input() disabled: Boolean;
  /** Trip Input, for setting a static trip from the parent component and disabling the Trip Selector dropdown */
  @Input() trip: TripAttributes;
  allowTripSelection = true;
  @Input() okayType: OkayType;
  @Input() tripSummaryContext: boolean;
  @Output() onSave = new EventEmitter<void>();

  @ViewChild('okayModal') okayModal: CiaoModalComponent;

  tripControl = new FormControl<TripAttributes>(null, Validators.required);
  timeOkayControl = new FormControl<Date>(null, Validators.required);
  notesControl = new FormControl<string>('', FormsUtility.isOverCharLimit);
  crewMembersControl = new FormControl<string[]>(
    [],
    [ValidatorAtLeastOneSelected]
  );

  myFormGroup = new FormGroup({
    trip: this.tripControl,
    timeOkay: this.timeOkayControl,
    notes: this.notesControl,
    crewMembers: this.crewMembersControl,
  });
  @Input() buttonClass?: string;
  /** Setting this will override the value set by the okayType */
  @Input() buttonText: string;
  /** Setting this will override the value set by the okayType */
  @Input() title: string;
  /** Setting this will override the value set by the okayType */
  @Input() description: string;

  canSendOkayAtAll$ = this.rolePermissionService
    .searchMyAppliedPermissions({ permissionIds: ['send_okay'] })
    .pipe(
      map((perms) => perms.length === 0),
      share()
    );

  trips$?: Observable<{ label: string; value: TripAttributes }[]>;
  crewMembers$: Observable<SelectOption[]> = this.tripControl.valueChanges.pipe(
    startWith(this.tripControl.value),
    map((trip) => {
      if (!this.tripControl.value?.crewMembersProcessed) {
        return [
          {
            value: 'error',
            label:
              "There's been an error.  We can't find the Crew Member list.  This is a bug.",
          },
        ];
      }
      return this.tripControl.value.crewMembersProcessed.map((crew) => ({
        value: crew.id,
        label: crew.displayName,
        // set disabled to true if crew is checkedIn
        disabled: crew.crewMemberStatus === 'CheckedIn' ? true : false,
      }));
    }),
    tap((options) => {
      let value = [];
      if (
        options.length === 1 ||
        ['checkOut', 'okay'].includes(this.okayType)
      ) {
        // If it's check Out or Okay, select all the crew members.
        // If there's only one crew member select that crew member (which is "all the crew members")
        value = options.map((option) => option.value);
      } else {
        // If there's multiple people and they're checking In, make user select people manually.
        value = [];
      }
      this.crewMembersControl.setValue(value, {
        emitEvent: false,
        emitModelToViewChange: false,
      });
    })
  );

  readableOkayType: string;
  buttonIcon?: IconProp;
  showStart?: boolean;
  showEnd?: boolean;
  errorModalMessage = '';
  plannedTrips$: Observable<{ label: string; value: TripAttributes }[]>;
  openAndLateTrips$: Observable<{ label: string; value: TripAttributes }[]>;
  sendingRequest: boolean = false;
  displayValues: ModalConstType;

  // font awesome
  mountain = faMountain;
  thumbsUp = faThumbsUp;
  hiking = faHiking;

  constructor(
    private toastrService: ToastrService,
    private tripService: TripService,
    private textService: TextService,
    private rolePermissionService: RolePermissionService
  ) {}

  ngOnInit(): void {
    this.reinitializeValues();
  }

  reinitializeValues() {
    this.refreshTrips();
    {
      let values = this.getModalConsts(this.okayType);

      this.displayValues = values;
      this.readableOkayType = values.readableOkayType;
      this.buttonIcon = values.buttonIcon;
      this.showStart = values.showStart;
      this.showEnd = values.showEnd;
      this.trips$ = values.trips$;
      this.title = this.title || values.title;
      this.description = this.description || values.description;
      this.buttonText = this.buttonText || values.buttonText;
    }
    this.allowTripSelection = !this.trip;

    this.myFormGroup.reset();
    this.myFormGroup.patchValue({
      timeOkay: new Date(),
      notes: '',
      crewMembers: [],
      // Setting trip must come last to avoid being overwritten
      trip: this.trip,
    });
  }

  refreshTrips() {
    this.plannedTrips$ = this.searchForTripsByStatus('Planned').pipe(
      this.op_paginationResultsToOptions
    );
    let openTrips$ = this.searchForTripsByStatus('Open').pipe(
      this.op_paginationResultsToOptions
    );
    let lateTrips$ = this.searchForTripsByStatus('Late').pipe(
      this.op_paginationResultsToOptions
    );

    this.openAndLateTrips$ = combineLatest([lateTrips$, openTrips$]).pipe(
      map(([lateTrips, openTrips]) => [...lateTrips, ...openTrips])
    );
  }

  searchForTripsByStatus(status: string) {
    return this.tripService.search({
      limit: 25,
      offset: 0,
      order: [['startDate', 'ASC']],
      where: [['tripStatus', status], ['imNamedOnThisTrip']],
    });
  }

  op_paginationResultsToOptions(
    input: Observable<PaginationResult<TripAttributes>>
  ) {
    return input.pipe(
      map((results) => results.rows),
      map((trips) =>
        trips.map((trip) => ({
          label: `${
            trip.crewMembers[0]?.crewMember?.displayName
          } | ${trip.startDate.substring(0, 10)} | ${trip.usergroup?.label}`,
          value: trip,
        }))
      ),
      shareReplay(1)
    );
  }

  SendOkay() {
    if (!this.myFormGroup.value.trip) {
      // Too much depends on value.trip, there is no coming back form that.
      // So this.myFormGroup.value.trip?.whatever doesn't make any sense here.
      this.toastrService.error(
        `Trip was not updated successfully. Error: Trip Not Selected`,
        'Error'
      );
      console.error(
        'Something is very wrong.  Trip Not Selected.  Form Value:',
        this.myFormGroup.value
      );
      throw new Error('Trip Not Selected');
    }
    let okay: OkayAttributes = {
      tripId: this.myFormGroup.value.trip.id,
      type: this.okayType,
      timeOkay: this.myFormGroup.value.timeOkay,
      notes: this.myFormGroup.value.notes,
      crewMembersSelected: this.crewMembersControl.value.map((id) => ({
        id: id,
      })),
    };
    let crewMemberReadableList = this.crewMembersControl.value
      .map((id) =>
        this.myFormGroup.value.trip.crewMembersProcessed.find(
          (person) => person.id === id
        )
      )
      .map((crew) => `${crew?.firstName?.[0] ?? '?'}. ${crew?.lastName ?? '?'}`)
      .join(', ');
    // set this to true so button becomes disabled until response
    this.sendingRequest = true;

    let okaySaveConfirmation = this.tripService.addOkay(okay);
    okaySaveConfirmation
      .pipe(
        tap((trip) => {
          this.okayModal.dismiss('completed');
          this.sendingRequest = false;

          if (this.okayType == 'checkIn' && trip.tripStatus === 'Closed') {
            this.toastrService.success(
              `Entire crew has checked in successfully. Trip status is now Closed.`,
              'Success'
            );
          } else if (this.okayType == 'checkIn') {
            // Open or Late
            // Planned trips would represent an unexpected state,
            //   but would be better to have this message than the one at the bottom.
            this.toastrService.success(
              `${crewMemberReadableList} has checked in successfully. Trip status remains Open.`,
              'Success'
            );
          } else if (this.okayType == 'okay') {
            this.toastrService.success(
              `Trip was marked as "okay" successfully.`,
              'Success'
            );
          } else {
            this.toastrService.success(
              `Trip has been checked out of successfully. Trip status is now Open.`,
              'Success'
            );
          }
        }),
        catchError((err) => {
          this.errorModalMessage = err.message;
          this.okayModal.dismiss('error');
          this.sendingRequest = false;

          this.toastrService.error(
            `Trip was not updated successfully. Error: ${err}`,
            'Error'
          );
          return of(null);
        }),
        tap(() => {
          this.onSave.emit();
        })
      )
      .subscribe();
  }

  getTripLocation(trip: TripAttributes) {
    let primaryLocation = trip.locations[0];
    let displayLocation = primaryLocation
      ? `${primaryLocation.description}`
      : '';
    return displayLocation;
  }

  getTripStartTime(trip: TripAttributes) {
    return this.textService.formatDatetime(trip.startDate);
  }

  getTripDisplayData(trip: TripAttributes) {
    let primaryLocation = trip.locations[0];
    let displayLocation = primaryLocation
      ? `${primaryLocation.description}`
      : '';
    let dataPoints = [
      '<b>Location</b>' + '<br>' + displayLocation,
      '<b>Start</b>' + '<br>' + this.textService.formatDateOnly(trip.startDate),
    ];
    return dataPoints.join('<br><br>');
  }
  getModalConsts(okayType: OkayType): ModalConstType {
    switch (okayType) {
      case 'checkOut':
        return {
          title: 'Check Out',
          description: '',
          okayType: 'checkOut',
          readableOkayType: 'Check Out',
          buttonText: 'Check Out',
          buttonIcon: this.mountain as IconProp,
          noTeamMessagePart: 'check out for a trip',
          showStart: true,
          showEnd: false,
          trips$: this.plannedTrips$,
        };
      case 'okay':
        return {
          title: "I'm Okay",
          // Possible description 'Mark this crew as okay at this time.'
          description: '',
          okayType: 'okay',
          readableOkayType: 'Okay',
          buttonText: "I'm Okay",
          buttonIcon: this.thumbsUp as IconProp,
          noTeamMessagePart: 'send an "I\'m Okay" for a trip',
          showStart: false,
          showEnd: true,
          trips$: this.openAndLateTrips$,
        };
      case 'checkIn':
        return {
          title: 'Check In',
          description: '',
          okayType: 'checkIn',
          readableOkayType: 'Check In',
          buttonText: 'Check In',
          buttonIcon: this.hiking as IconProp,
          noTeamMessagePart: 'check in from a trip',
          showStart: false,
          showEnd: true,
          trips$: this.openAndLateTrips$,
        };
    }
  }
}

type ModalConstType = {
  title: string;
  description: string;
  okayType: string;
  readableOkayType: string;
  buttonText: string;
  buttonIcon: IconProp;
  noTeamMessagePart: string;
  showStart: boolean;
  showEnd: boolean;
  trips$: Observable<
    {
      label: string;
      value: TripAttributes;
    }[]
  >;
};
