import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '~app/services';
import { UserProfileFormComponent } from './user-profile-form/user-profile-form.component';
import { UserProfileTeamsTableComponent } from './user-profile-teams-table/user-profile-teams-table.component';

@Component({
  selector: 'ciao-user-profile-page',
  standalone: true,
  imports: [
    CommonModule,
    UserProfileTeamsTableComponent,
    UserProfileFormComponent,
  ],
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.less', 'shared.less'],
})
export class UserProfilePageComponent {
  constructor(private readonly authService: AuthService) {}

  readonly currentUser$ = this.authService.currentUser$;
  readonly personId$ = this.authService.personId$;
}
