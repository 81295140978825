import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigDataService } from '~app/services/config-data.service';
import { map, switchMap, take } from 'rxjs/operators';

export const WAIT_FOR_CONFIG_IGNORE_TOKEN = new HttpContextToken(() => true);

@Injectable()
export class WaitForConfigInterceptor implements HttpInterceptor {
  constructor(private readonly configDataService: ConfigDataService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.context.has(WAIT_FOR_CONFIG_IGNORE_TOKEN)) {
      return next.handle(request);
    } else {
      return this.configDataService.apiUrl$.pipe(
        take(1),
        map((apiUrl) => {
          return request.clone({
            withCredentials: true,
            url: request.url.replace('[API_URL]', apiUrl),
          });
        }),
        switchMap((request) => next.handle(request))
      );
    }
  }
}
