import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import type { ICellEditorParams } from 'ag-grid-community';
import { BehaviorSubject, of } from 'rxjs';
import {
  filter,
  map,
  share,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CiaoFormsModule } from '~app/components/shared/forms/forms.module';
import { PersonAttributes } from '~app/models';
import { UserGroupService } from '~app/services/user-group.service';
import { crewOptions } from './data';

@Component({
  selector: 'ciao-crew-cell-editor',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    CiaoFormsModule,
  ],
  templateUrl: './crew-cell-editor.component.html',
  styleUrl: './crew-cell-editor.component.less',
})
export class CrewCellEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  params: any;
  public crewValue: PersonAttributes;
  options$;

  formGroup = new FormGroup({
    crewControl: new FormControl<PersonAttributes>(null),
  });

  @ViewChild('picker') picker: ElementRef;

  userGroupId$ = new BehaviorSubject<string>(null);
  readonly crewMemberOptions$ = this.userGroupService
    .getCrewMemberDropdownLists('d5a96104-9d71-4ac4-bd74-7e15a7e2706b')
    .pipe(
      map((listByRoles) => listByRoles.crew_member),
      tap((c) => {
        console.log(c);
      }),
      share()
    );

  tempCrewOptions = of(crewOptions);

  constructor(private userGroupService: UserGroupService) {}

  agInit(params: any) {
    this.params = params;
    this.crewValue = params.value;
  }

  ngOnInit() {
    this.formGroup.controls.crewControl.setValue(this.crewValue, {
      emitEvent: false,
    });
  }

  ngAfterViewInit() {}

  comparePersonForSelect(a: any, b: any) {
    if (a === b) {
      return true;
    }
    if (a && b && a?.id === b?.id) {
      // compare the ids, but not if a or b is null.
      return true;
    }
    return false;
  }

  onChangeClick(event: MatAutocompleteSelectedEvent) {
    this.crewValue = event?.option?.value?.value ?? null;
    // lets the editor know to stop rendering value
    this.params.stopEditing();
  }
  refresh(params) {
    return true;
  }
  isPopup(): boolean {
    return true;
  }
  getValue() {
    return this.crewValue;
  }
}
