import {
  Component,
  Input,
  Signal,
  ViewChild,
  computed,
  signal,
} from '@angular/core';
import { AsyncPipe, NgFor, NgForOf, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, Observable, combineLatest, forkJoin } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import {
  IsLoadingPaginationResult,
  PaginationResult,
  PersonAttributes,
} from '~app/models';
import { UserGroupAttributes } from '~app/models/user-group';
import { UserGroupService } from '~app/services/user-group.service';
import { SimpleStringSortBy } from '~app/utilities/simple-sortby';

import { CiaoFormsModule } from '~components/shared/forms/forms.module';
import { CiaoSharedModule } from '~components/shared/shared.module';
import { PageSelectorComponent } from '~components/report-table/page-selector/page-selector.component';
import { pageData } from '~components/report-table/client-side-pagination.functions';
import { NgForTrackByIdDirective } from '~app/utilities/ng-for-track-by-id.directive';
import { UserGroupModalComponent } from '../../user-group-page/user-group-modal/user-group-modal.component';

type MyTeamsTableRow = {
  team: UserGroupAttributes;
  members: {
    person: PersonAttributes;
    team_member: boolean;
    supervisor: boolean;
    maxRole: 'Supervisor' | 'Team Member';
  }[];
  expanded: Signal<boolean>;
};

@Component({
  selector: 'ciao-user-profile-teams-table',
  standalone: true,
  imports: [
    AsyncPipe,
    NgFor,
    NgForOf,
    NgIf,
    MatIconModule,
    CiaoFormsModule,
    CiaoSharedModule,
    PageSelectorComponent,
    NgForTrackByIdDirective,
    UserGroupModalComponent,
  ],
  templateUrl: './user-profile-teams-table.component.html',
  styleUrls: ['./user-profile-teams-table.component.less', '../shared.less'],
})
export class UserProfileTeamsTableComponent {
  constructor(private readonly userGroupService: UserGroupService) {}

  @ViewChild('userGroupModal') userGroupModal: UserGroupModalComponent;

  readonly personId$ = new BehaviorSubject('');
  @Input() set personId(val: string) {
    this.personId$.next(val);
  }
  get personId() {
    return this.personId$.value;
  }
  /**
   * @see [isExpanded]
   * @see [expand]
   */
  selectedTeamId = signal('');

  readonly teamsList$ = this.getTeamsListObservable();

  pagingInfo$ = new BehaviorSubject({ limit: 0, offset: 0 });
  readonly paginationResult$: Observable<PaginationResult<MyTeamsTableRow>> =
    combineLatest([this.teamsList$, this.pagingInfo$]).pipe(
      map(([groups, { limit, offset }]) => pageData(groups, limit, offset)),
      startWith(IsLoadingPaginationResult())
    );

  isExpanded(id) {
    return computed(() => {
      return this.selectedTeamId() === id;
    });
  }
  expand(id) {
    if (this.selectedTeamId() === id) {
      this.selectedTeamId.set('');
    } else {
      this.selectedTeamId.set(id);
    }
  }

  openTeamModal(team: UserGroupAttributes) {
    this.userGroupModal.openModal(team, 'Edit');
  }

  processTeamMembersIntoTeam(
    rawMemberList: {
      team_member: PersonAttributes[];
      supervisor: PersonAttributes[];
    },
    team: UserGroupAttributes
  ) {
    let membersById: {
      [id: string]: MyTeamsTableRow['members'][number];
    } = {};
    rawMemberList.team_member.forEach((person) => {
      membersById[person.id] = {
        person,
        team_member: true,
        supervisor: false,
        maxRole: 'Team Member',
      };
    });
    rawMemberList.supervisor.forEach((person) => {
      if (membersById[person.id]) {
        membersById[person.id].supervisor = true;
        membersById[person.id].maxRole = 'Supervisor';
      } else {
        membersById[person.id] = {
          person,
          team_member: false,
          supervisor: true,
          maxRole: 'Supervisor',
        };
      }
    });
    let memberList = Object.keys(membersById)
      .map((key) => membersById[key])
      .sort(SimpleStringSortBy('person.displayName'));

    return {
      team: team,
      members: memberList,
      expanded: this.isExpanded(team.id),
    };
  }

  getTeamsListObservable() {
    return this.personId$.pipe(
      switchMap((personId) =>
        this.userGroupService.searchUserGroups({
          personIds: [this.personId],
          roleIds: ['team_member', 'supervisor'],
        })
      ),
      this.userGroupService.op_sortGroups,
      switchMap((teams) => {
        let memberLists$ = teams.map((team) =>
          this.userGroupService.getMembersByRole(team.id, [
            'team_member',
            'supervisor',
          ])
        );
        let teamList$ = forkJoin(memberLists$).pipe(
          map((memberLists) =>
            memberLists.map((rawMemberList, index) =>
              this.processTeamMembersIntoTeam(rawMemberList, teams[index])
            )
          )
        );

        return teamList$;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
