<ciao-form-field
  class="form-field col-lg-6 col-sm-12"
  type="combobox"
  [label]="'crew member'"
  [agGridContext]="true"
  [formControlInput]="formGroup.get('crewControl')"
  [selectOptions$]="tempCrewOptions"
  (selectionChangeEvent)="onChangeClick($event)"
  [compareWith]="comparePersonForSelect"
>
</ciao-form-field>
