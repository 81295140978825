import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TripAttributes } from '~app/models';
import { TextService } from '~app/services';

@Component({
  selector: 'ciao-trip-log-section-v3',
  standalone: true,
  imports: [NgIf, NgFor],
  templateUrl: './trip-log-section.component.html',
  styleUrl: './trip-log-section.component.less',
})
export class TripLogSectionComponent {
  constructor(private readonly textService: TextService) {}
  @Input() trip: TripAttributes;

  formatDate(date: Date | string) {
    return this.textService.formatSimpleDatetime(date);
  }

  getReadableOkayType(okay) {
    let readableType =
      {
        checkOut: 'Check Out',
        checkIn: 'Check In',
        okay: "I'm Okay",
      }[okay.type] ?? '';
    let closedTripIndicator = okay.closedTrip ? ': Trip Closed' : '';
    return readableType + closedTripIndicator;
  }
}
