import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatetimeService } from '~app/services/datetime.service';

@Pipe({
  name: 'ciaoDate',
  standalone: true,
})
export class CiaoDatePipe implements PipeTransform {
  readonly;
  constructor(
    private readonly dateTimeService: DatetimeService,
    @Inject(LOCALE_ID) private readonly _locale: string
  ) {}

  transform(
    value: Date | string,
    parts: 'date' | 'time' | 'datetime',
    style: 'readable' | 'filter' | 'simple'
  ): string {
    let date = this.dateTimeService.extractDate(value);
    if (!date) {
      return '';
    }
    if (isNaN(date.valueOf())) {
      console.error('Received Invalid Date:', JSON.stringify(value));
      return '';
    }
    if (!['readable', 'filter', 'simple'].includes(style)) {
      console.error('Invalid Style');
      return 'Invalid DatePipe';
    }

    let datePart = this.dateTimeService.getDatePart({ date, style });
    let timePart = this.dateTimeService.getTimePart({ date, style });
    let combiner: string = ' | ';

    if (style === 'filter') {
      combiner = ' ';
    }

    switch (parts) {
      case 'date':
        return datePart;
      case 'time':
        return timePart;
      case 'datetime':
        return datePart + combiner + timePart;
      default:
        console.error('Invalid Parts');
        return 'Invalid DatePipe';
    }
  }
}
