<div id="summary">
  <div class="header">
    <div id="title">
      <p>Trip Summary</p>
      <!-- TODO: Add back when pdf functionality is implemented -->
      <!-- <button class="btn-action-solid" (click)="saveToPdf()">
    Save to PDF
  </button> -->
    </div>
  </div>

  <div id="summary-sections">
    <div id="left-side" class="column">
      <ciao-okay-modal-section-v3
        [trip]="trip"
        id="checkout-btns"
        class="btns"
      />
      <ciao-trip-log-section-v3
        [trip]="trip"
        id="logs"
        class="section list scroll"
      />
    </div>

    <div
      id="right-side"
      class="column"
      [ngClass]="{ tripClosed: trip?.tripStatus === 'Closed' }"
    >
      <div id="details" class="section">
        <div class="table-header">
          <h3 aria-label="details table header" class="title">Details</h3>
          <button
            *ngIf="trip?.tripStatus !== 'Closed'"
            mat-icon-button
            [matMenuTriggerFor]="tripDetailsMenu"
            aria-label="Trip Options"
            class="semantic-button"
          >
            <span class="screenreader">Trip Options</span>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #tripDetailsMenu>
            <ng-template matMenuContent let-id="id">
              <button
                mat-menu-item
                [routerLink]="['/trip', 'duplicate', trip.id]"
              >
                <!-- adding inline svg here ( might want to add all these as custom icons at a later point ) -->
                <svg
                  width="32"
                  height="32"
                  viewBox="0 -5 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 19H15C14.7348 19 14.4804 18.8946 14.2929 18.7071C14.1054 18.5196 14 18.2652 14 18C14 17.7348 14.1054 17.4804 14.2929 17.2929C14.4804 17.1054 14.7348 17 15 17H17.5C17.6321 16.9983 17.7583 16.9451 17.8517 16.8517C17.9451 16.7583 17.9983 16.6321 18 16.5V2.5C17.9983 2.36791 17.9451 2.24169 17.8517 2.14828C17.7583 2.05487 17.6321 2.00166 17.5 2H5.5C5.36791 2.00166 5.24169 2.05487 5.14828 2.14828C5.05487 2.24169 5.00166 2.36791 5 2.5V5C5 5.26522 4.89464 5.51957 4.70711 5.70711C4.51957 5.89464 4.26522 6 4 6C3.73478 6 3.48043 5.89464 3.29289 5.70711C3.10536 5.51957 3 5.26522 3 5V2.5C3.0007 1.83717 3.26431 1.20169 3.733 0.733002C4.20169 0.264312 4.83717 0.000696197 5.5 0H17.5C18.1628 0.000696197 18.7983 0.264312 19.267 0.733002C19.7357 1.20169 19.9993 1.83717 20 2.5V16.5C19.9993 17.1628 19.7357 17.7983 19.267 18.267C18.7983 18.7357 18.1628 18.9993 17.5 19Z"
                    fill="currentcolor"
                  />
                  <path
                    d="M13.5 22H2.5C1.83717 21.9993 1.20169 21.7357 0.733002 21.267C0.264312 20.7983 0.000696197 20.1628 0 19.5V6.5C0.000696197 5.83717 0.264312 5.20169 0.733002 4.733C1.20169 4.26431 1.83717 4.0007 2.5 4H13.5C14.1628 4.0007 14.7983 4.26431 15.267 4.733C15.7357 5.20169 15.9993 5.83717 16 6.5V19.5C15.9993 20.1628 15.7357 20.7983 15.267 21.267C14.7983 21.7357 14.1628 21.9993 13.5 22ZM2.5 6C2.36744 6.00017 2.24037 6.05291 2.14664 6.14664C2.05291 6.24037 2.00017 6.36744 2 6.5V19.5C2.00017 19.6326 2.05291 19.7596 2.14664 19.8534C2.24037 19.9471 2.36744 19.9998 2.5 20H13.5C13.6326 19.9998 13.7596 19.9471 13.8534 19.8534C13.9471 19.7596 13.9998 19.6326 14 19.5V6.5C13.9998 6.36744 13.9471 6.24037 13.8534 6.14664C13.7596 6.05291 13.6326 6.00017 13.5 6H2.5Z"
                    fill="currentcolor"
                  />
                </svg>
                Duplicate
              </button>
              <button mat-menu-item (click)="debug('Not deleting right now')">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
              <button mat-menu-item (click)="tripService.addToCalendar(trip)">
                <mat-icon>event</mat-icon>
                Add To Calendar
              </button>
              <a mat-menu-item [routerLink]="['/trip', 'id', trip.id]">
                <mat-icon>filter_2</mat-icon>
                Trip Summary <br />
                (old page retained for reference. ciao version 1.x)
              </a>
            </ng-template>
          </mat-menu>
        </div>
        <div class="info-row">
          <div>
            <span class="data-title"> Team: </span>

            <span
              >{{ trip?.usergroup?.labelPrefix }}
              {{ trip?.usergroup?.label }}</span
            >
          </div>
          <div>
            <span class="data-title"> Start Time/Date: </span>
            <span>{{
              trip?.startDate | ciaoDate : "datetime" : "simple"
            }}</span>
          </div>
          <div>
            <span class="data-title"> Return Time/Date: </span
            ><span>{{ trip?.endDate | ciaoDate : "datetime" : "simple" }}</span>
          </div>
        </div>
      </div>

      <div id="tripmap-container" class="section">
        <ciao-trip-map />
      </div>

      <div id="locations" class="section">
        <ng-container>
          <div class="table-header">
            <h3 id="locations-table-header" class="title">Locations</h3>
          </div>
          <!-- locations grid table -->
          <ciao-location-grid
            #locationsGrid
            [rowData]="trip?.locations"
            [defaultRowSelection]="defaultRowSelection"
          ></ciao-location-grid>
        </ng-container>

        <ng-template #locMissingContent>
          <h3 class="default-content">No Locations assigned to trip</h3>
        </ng-template>
      </div>
      <div id="crew" class="section">
        <ng-container>
          <div class="table-header">
            <h3 id="crew-members-table-header" class="title">Crew Members</h3>
          </div>
          <ciao-crew-grid
            [rowData]="trip?.crewMembers"
            [defaultColDef]="defaultColDef"
            [defaultRowSelection]="defaultRowSelection"
          ></ciao-crew-grid>
        </ng-container>

        <ng-template #crewMissingContent>
          <h3 class="default-content">No Crew Members assigned to trip</h3>
        </ng-template>
      </div>
      <div id="equipment" class="section">
        <ng-container>
          <div class="table-header">
            <h3 id="equipment-table-header" class="title">Equipment</h3>
          </div>

          <ciao-equipment-grid
            [rowData]="trip?.equipmentList"
            [defaultColDef]="defaultColDef"
            [defaultRowSelection]="defaultRowSelection"
          ></ciao-equipment-grid>
        </ng-container>

        <ng-template #equipmentMissingContent>
          <h3 class="default-content">No Equipment assigned to trip</h3>
        </ng-template>
      </div>
      <div id="attachments" class="section">
        <ng-container>
          <div class="table-header">
            <h3 id="attachment-table-header" class="title">Attachments</h3>
          </div>
          <ciao-attachment-grid
            [rowData]="trip?.attachments"
            [defaultColDef]="defaultColDef"
            [defaultRowSelection]="defaultRowSelection"
          ></ciao-attachment-grid>
        </ng-container>

        <ng-template #attachmentMissingContent>
          <h3 class="default-content">No Attachments</h3>
        </ng-template>
      </div>
      <div class="btn-group">
        <button class="btn-action-light">
          <!-- adding inline svg here ( might want to add all these as custom icons at a later point ) -->
          <svg
            width="32"
            height="32"
            viewBox="-6 -5 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 19H15C14.7348 19 14.4804 18.8946 14.2929 18.7071C14.1054 18.5196 14 18.2652 14 18C14 17.7348 14.1054 17.4804 14.2929 17.2929C14.4804 17.1054 14.7348 17 15 17H17.5C17.6321 16.9983 17.7583 16.9451 17.8517 16.8517C17.9451 16.7583 17.9983 16.6321 18 16.5V2.5C17.9983 2.36791 17.9451 2.24169 17.8517 2.14828C17.7583 2.05487 17.6321 2.00166 17.5 2H5.5C5.36791 2.00166 5.24169 2.05487 5.14828 2.14828C5.05487 2.24169 5.00166 2.36791 5 2.5V5C5 5.26522 4.89464 5.51957 4.70711 5.70711C4.51957 5.89464 4.26522 6 4 6C3.73478 6 3.48043 5.89464 3.29289 5.70711C3.10536 5.51957 3 5.26522 3 5V2.5C3.0007 1.83717 3.26431 1.20169 3.733 0.733002C4.20169 0.264312 4.83717 0.000696197 5.5 0H17.5C18.1628 0.000696197 18.7983 0.264312 19.267 0.733002C19.7357 1.20169 19.9993 1.83717 20 2.5V16.5C19.9993 17.1628 19.7357 17.7983 19.267 18.267C18.7983 18.7357 18.1628 18.9993 17.5 19Z"
              fill="currentcolor"
            />
            <path
              d="M13.5 22H2.5C1.83717 21.9993 1.20169 21.7357 0.733002 21.267C0.264312 20.7983 0.000696197 20.1628 0 19.5V6.5C0.000696197 5.83717 0.264312 5.20169 0.733002 4.733C1.20169 4.26431 1.83717 4.0007 2.5 4H13.5C14.1628 4.0007 14.7983 4.26431 15.267 4.733C15.7357 5.20169 15.9993 5.83717 16 6.5V19.5C15.9993 20.1628 15.7357 20.7983 15.267 21.267C14.7983 21.7357 14.1628 21.9993 13.5 22ZM2.5 6C2.36744 6.00017 2.24037 6.05291 2.14664 6.14664C2.05291 6.24037 2.00017 6.36744 2 6.5V19.5C2.00017 19.6326 2.05291 19.7596 2.14664 19.8534C2.24037 19.9471 2.36744 19.9998 2.5 20H13.5C13.6326 19.9998 13.7596 19.9471 13.8534 19.8534C13.9471 19.7596 13.9998 19.6326 14 19.5V6.5C13.9998 6.36744 13.9471 6.24037 13.8534 6.14664C13.7596 6.05291 13.6326 6.00017 13.5 6H2.5Z"
              fill="currentcolor"
            />
          </svg>

          Duplicate Trip
        </button>
        <button class="btn-action-light">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8799 16.0001L23.6133 10.2801C23.8643 10.029 24.0054 9.68849 24.0054 9.33342C24.0054 8.97835 23.8643 8.63782 23.6133 8.38675C23.3622 8.13568 23.0217 7.99463 22.6666 7.99463C22.3115 7.99463 21.971 8.13568 21.7199 8.38675L15.9999 14.1201L10.2799 8.38675C10.0288 8.13568 9.68832 7.99463 9.33325 7.99463C8.97818 7.99463 8.63766 8.13568 8.38659 8.38675C8.13551 8.63782 7.99446 8.97835 7.99446 9.33342C7.99446 9.68849 8.13551 10.029 8.38659 10.2801L14.1199 16.0001L8.38659 21.7201C8.26161 21.844 8.16242 21.9915 8.09473 22.154C8.02704 22.3165 7.99219 22.4907 7.99219 22.6667C7.99219 22.8428 8.02704 23.017 8.09473 23.1795C8.16242 23.342 8.26161 23.4895 8.38659 23.6134C8.51054 23.7384 8.658 23.8376 8.82048 23.9053C8.98296 23.973 9.15724 24.0078 9.33325 24.0078C9.50927 24.0078 9.68354 23.973 9.84602 23.9053C10.0085 23.8376 10.156 23.7384 10.2799 23.6134L15.9999 17.8801L21.7199 23.6134C21.8439 23.7384 21.9913 23.8376 22.1538 23.9053C22.3163 23.973 22.4906 24.0078 22.6666 24.0078C22.8426 24.0078 23.0169 23.973 23.1794 23.9053C23.3418 23.8376 23.4893 23.7384 23.6133 23.6134C23.7382 23.4895 23.8374 23.342 23.9051 23.1795C23.9728 23.017 24.0077 22.8428 24.0077 22.6667C24.0077 22.4907 23.9728 22.3165 23.9051 22.154C23.8374 21.9915 23.7382 21.844 23.6133 21.7201L17.8799 16.0001Z"
              fill="currentcolor"
            />
          </svg>
          Delete Trip
        </button>
      </div>
    </div>
  </div>
</div>
