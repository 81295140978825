import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  RowSelectionOptions,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ValueFormatterParams,
  CellEditingStoppedEvent,
  RowClassParams,
  GridOptions,
} from 'ag-grid-community'; // Column Definition Type Interface
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { NgIf } from '@angular/common';
import { CountyAttributes, PlotAttributes, StateAttributes } from '~app/models';
import { stateList } from './data';
import { ToastrService } from 'ngx-toastr';

interface locationRow {
  id: string;
  description: string;
  state: string;
  county: string;
  countyCode: string;
  plotNumber: string;
}
[];
@Component({
  selector: 'ciao-location-grid',
  standalone: true,
  imports: [AgGridAngular, FontAwesomeModule, NgIf],
  templateUrl: './location-grid.component.html',
  styleUrl: './location-grid.component.less',
})
export class LocationGridComponent {
  @Input() rowData: PlotAttributes[];

  @Input() defaultColDef: ColDef = {
    flex: 1,
    cellStyle: (params) => {
      return { textAlign: 'left', cursor: 'pointer', color: '#2a5f04' };
    },
    valueFormatter: (params: ValueFormatterParams): any => {
      return this.isEmptyPinnedCell(params)
        ? this.createPinnedCellPlaceholder(params)
        : undefined;
    },
    onCellValueChanged: (params): any => {
      if (params.node.rowPinned === 'top') {
        return;
      }

      // While this is still POC we will not save data instead just spoof
      //this.saveLocationData(params.data);
      this.toastr.success(
        'Data has not been saved. Changes will disapear after refresh',
        'POC #2'
      );
    },
  };

  @Input() defaultRowSelection: RowSelectionOptions | 'single' | 'multiple';

  @Output() onSave = new EventEmitter<any>();
  showDeleteButton = false;

  // Input for new row
  inputRow: {} = {};
  pinnedTopRowData = [this.inputRow];

  gridApi!: GridApi;

  processedRowData: locationRow[] = [];
  faDelete = faTrash;

  // field select content
  stateOptions$: Observable<{ value: StateAttributes; label: string }[]>;
  countyOptions$: Observable<{ value: CountyAttributes; label: string }[]>;

  locationColDefs: ColDef[] = [
    {
      field: 'id',
      hide: true,
    },
    {
      headerName: 'Description',
      headerTooltip: 'This is a tooltip',
      field: 'description',
      flex: 2,
      editable: true,
      cellEditorPopup: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: 250,
        rows: 10,
        cols: 50,
      },
    },
    {
      headerName: 'State',
      field: 'state',
      flex: 1,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: stateList,
      },
    },
    {
      headerName: 'County',
      field: 'county',
      flex: 1,
      cellDataType: 'text',
      editable: true,
    },
    {
      headerName: 'County Code',
      field: 'countyCode',
      flex: 1,
      editable: true,
    },
    {
      headerName: 'Plot Number',
      field: 'plotNumber',
      flex: 1,
      editable: true,
    },
  ];

  gridOptions: GridOptions = {
    dataTypeDefinitions: {
      location: {
        extendsDataType: 'object',
        baseDataType: 'object',
      },
    },
    // define cols here to access custom object defs
    columnDefs: this.locationColDefs,
  };

  getRowStyle = ({ node }: RowClassParams) =>
    node.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : {};

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data?.id;
  };

  constructor(private toastr: ToastrService) {}

  ngOnInit() {}

  // keeping these here for now since they dont hurt
  hideBoxes() {
    this.gridApi.setGridOption('rowSelection', {
      mode: 'multiRow',
      checkboxes: false,
    });
  }
  showBoxes() {
    this.gridApi.setGridOption('rowSelection', {
      mode: 'multiRow',
      checkboxes: true,
    });
  }

  handleRowSelection(event) {
    // Show delete button if a box is selected
    this.gridApi.getSelectedRows().length > 0
      ? (this.showDeleteButton = true)
      : (this.showDeleteButton = false);
  }

  deleteSelectedRows() {
    const selectedRows = this.gridApi.getSelectedRows();
    const idsToRemove = new Set(selectedRows.map((item) => item));
    this.rowData = this.rowData.filter((item) => !idsToRemove.has(item));
  }

  gridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    console.log('grid is ready', this.rowData);
  }

  onCellEditingStopped(params: CellEditingStoppedEvent) {
    // interesting flash thing
    var rowNode = this.gridApi.getDisplayedRowAtIndex(params.rowIndex);
    // dont flash pinned row on each cell edit
    if (!params.rowPinned) {
      this.gridApi.flashCells({ rowNodes: [rowNode] });
    }
    // deselect all boxes after the edit finishes
    this.gridApi.deselectAll();
    if (this.isPinnedRowDataCompleted(params)) {
      // add to the new processed list data
      this.rowData = [...this.rowData, this.inputRow as PlotAttributes];

      //reset pinned row
      this.inputRow = {};
      this.pinnedTopRowData = [this.inputRow];
      // While this is still POC we will not save data instead just spoof
      //this.onSave.emit(this.rowData);
      this.toastr.success(
        'Data has not been saved. Changes will disapear after refresh',
        'POC #2'
      );
    }
  }

  saveLocationData(rowData) {
    const newLocationData = this.convertDataToAttr(rowData);
    const index = this.rowData.findIndex(
      (row) => row.id === newLocationData.id
    );
    this.rowData[index] = newLocationData;
    this.onSave.emit(this.rowData);
  }

  get finalResult(): PlotAttributes[] {
    return this.rowData;
  }

  // take ag grid data and transform into saveable data
  convertDataToAttr(rowData) {
    return rowData;
  }

  private isEmptyPinnedCell({ node, value }: ValueFormatterParams): boolean {
    return (
      (node?.rowPinned === 'top' && value == null) ||
      (node?.rowPinned === 'top' && value === '')
    );
  }

  private createPinnedCellPlaceholder({
    colDef,
  }: ValueFormatterParams): string {
    return 'Enter ' + colDef.headerName;
  }
  private isPinnedRowDataCompleted({ rowPinned }): boolean {
    if (rowPinned !== 'top') return;

    return this.locationColDefs.every((def) => {
      if (def.field === 'id') {
        return true;
      }
      return this.inputRow[def.field];
    });
  }
}
