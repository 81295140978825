
<ng-container *ngIf="trip$ | async as trip">
	<div class="table-header">
	
	  <div style="position: relative;">
	    <div id="map"></div>
	    <button class="pointToggleButton {{ this.addPointLocationsEnabled ? 'pointToggleButtonEnabled' : 'pointToggleButtonDisabled' }}" 
	    	(click)="toggleAllowingAddingLocations()">
	    	{{ this.addPointLocationsEnabled ? "Stop Adding Locations" : "Add Locations" }}
	    </button>
	  </div>

	  <div  style="display:none;">
	  	<a *ngFor="let location of locations" 
	  		href="javascript:void(0);" 
	  		(click)="logImOk($event, location)" 
	  		class="imOkBridgeHref-{{location.id}}">im ok at {{location.description}}</a>
	  </div>

	</div>
</ng-container>
