export const crewOptions = [
  {
    value: {
      displayName: 'Andrew Jatnieks',
      displayNameDropdown: 'Andrew Jatnieks (andrew.jatnieks@usda.gov)',
      id: '2fd9984d-3d30-481d-b5c4-d70de909285e',
      firstName: 'Andrew',
      lastName: 'Jatnieks',
      middleName: '',
      preferredName: '',
      cellPhone: '',
      defaultSupervisorId: '9a7aed31-b98b-4bec-bbf8-b1d1b1fea48e',
      jobTitle: 'Contractor',
      users: [
        {
          id: '67432d7d-0370-42f1-84d9-b09f72239db2',
          email: 'andrew.jatnieks@usda.gov',
          notificationOptions: {
            defaultSet: true,
            initialized: true,
          },
        },
      ],
      AssignedRoles: [
        {
          RoleId: 'supervisor',
          UserGroupId: 'd5a96104-9d71-4ac4-bd74-7e15a7e2706b',
        },
      ],
      defaultPhone: {
        id: '6efa6825-73e9-4bac-aa77-f2e35db6f732',
        phoneType: 'government',
        default: true,
        phoneNumber: '(444) 444 - 4444',
        smsOptIn: true,
        smsNotifications: {
          beforeLate: true,
          exactlyWhenLate: true,
          repeatedAfterLate: true,
        },
        createdAt: '2024-11-15T17:07:12.746Z',
        updatedAt: '2024-11-15T17:09:00.424Z',
        personId: '2fd9984d-3d30-481d-b5c4-d70de909285e',
      },
    },
    label: 'Andrew Jatnieks (andrew.jatnieks@usda.gov)',
  },
  {
    value: {
      displayName: 'Colleen Kuvalis',
      displayNameDropdown: 'Colleen Kuvalis (No Email)',
      id: 'b0282f17-e682-42c6-9a55-3f63b3bb1bb4',
      firstName: 'Colleen',
      lastName: 'Kuvalis',
      middleName: 'fake',
      preferredName: '',
      cellPhone: '',
      defaultSupervisorId: null,
      jobTitle: null,
      users: [],
      AssignedRoles: [
        {
          RoleId: 'supervisor',
          UserGroupId: 'd5a96104-9d71-4ac4-bd74-7e15a7e2706b',
        },
      ],
      defaultPhone: null,
    },
    label: 'Colleen Kuvalis (No Email)',
  },
  {
    value: {
      displayName: 'Freddy Mercury',
      displayNameDropdown: 'Freddy Mercury (freddy.mercury.fake@usda.gov)',
      id: 'c096cedc-f8ad-4f09-bd90-fe100e7783e2',
      firstName: 'Freddy',
      lastName: 'Mercury',
      middleName: '',
      preferredName: '',
      cellPhone: '(815) 608-0335',
      defaultSupervisorId: null,
      jobTitle: 'FS Employee',
      users: [
        {
          id: '2c61240c-2469-4d12-ac9e-9cd05106c529',
          email: 'freddy.mercury.fake@usda.gov',
          notificationOptions: {
            defaultSet: false,
            initialized: true,
          },
        },
        {
          id: 'df2084fc-1705-45c8-bfdc-c5873bec7755',
          email: 'freddy.change@gmail.com',
          notificationOptions: {
            defaultSet: false,
            initialized: true,
          },
        },
      ],
      AssignedRoles: [
        {
          RoleId: 'supervisor',
          UserGroupId: 'd5a96104-9d71-4ac4-bd74-7e15a7e2706b',
        },
        {
          RoleId: 'team_member',
          UserGroupId: 'd5a96104-9d71-4ac4-bd74-7e15a7e2706b',
        },
      ],
      defaultPhone: {
        id: '9374f58d-ec61-4cc8-ac99-d560d859e547',
        phoneType: 'personal',
        default: true,
        phoneNumber: '(222) 222 - 2222',
        smsOptIn: false,
        smsNotifications: {
          beforeLate: false,
          exactlyWhenLate: false,
          repeatedAfterLate: false,
        },
        createdAt: '2024-01-24T19:34:39.712Z',
        updatedAt: '2024-11-25T20:05:34.344Z',
        personId: 'c096cedc-f8ad-4f09-bd90-fe100e7783e2',
      },
    },
    label: 'Freddy Mercury (freddy.mercury.fake@usda.gov)',
  },
  {
    value: {
      displayName: 'Lumpy space princess',
      displayNameDropdown: 'Lumpy space princess (lumpy_princess@usda.gov)',
      id: '73893870-886b-44fd-b204-172d1700a710',
      firstName: 'Lumpy',
      lastName: 'space princess',
      middleName: '',
      preferredName: '',
      cellPhone: '(939) 393 - 9393',
      defaultSupervisorId: 'de5b2e1f-203b-4779-a7c4-adb3291de59e',
      jobTitle: 'Contractor',
      users: [
        {
          id: '716bca44-c290-4663-b0a2-3a365cb04fc3',
          email: 'lumpy_princess@usda.gov',
          notificationOptions: {
            defaultSet: false,
            initialized: false,
          },
        },
      ],
      AssignedRoles: [
        {
          RoleId: 'team_member',
          UserGroupId: 'd5a96104-9d71-4ac4-bd74-7e15a7e2706b',
        },
      ],
      defaultPhone: null,
    },
    label: 'Lumpy space princess (lumpy_princess@usda.gov)',
  },
  {
    value: {
      displayName: 'Smokey1 Bear1',
      displayNameDropdown: 'Smokey1 Bear1 (smokey.bear@usda.gov)',
      id: '9a7aed31-b98b-4bec-bbf8-b1d1b1fea48e',
      firstName: 'Smokey1',
      lastName: 'Bear1',
      middleName: '',
      preferredName: '',
      cellPhone: '(555) 555-5555',
      defaultSupervisorId: '97dc06cb-cc14-445e-a2f4-4e58bc68fcbf',
      jobTitle: 'FS Employee',
      users: [
        {
          id: '4eed823f-2702-494e-845b-23230c9cb0f7',
          email: 'smokey.bear@usda.gov',
          notificationOptions: {
            defaultSet: true,
            initialized: true,
          },
        },
      ],
      AssignedRoles: [
        {
          RoleId: 'team_member',
          UserGroupId: 'd5a96104-9d71-4ac4-bd74-7e15a7e2706b',
        },
      ],
      defaultPhone: {
        id: 'aa9d05d2-174a-4af8-80db-dc3b0b6ac9ec',
        phoneType: 'personal',
        default: true,
        phoneNumber: '(123) 745 - 6655',
        smsOptIn: true,
        smsNotifications: {
          beforeLate: true,
          exactlyWhenLate: true,
          repeatedAfterLate: true,
        },
        createdAt: '2024-12-09T15:37:30.281Z',
        updatedAt: '2024-12-09T15:38:17.525Z',
        personId: '9a7aed31-b98b-4bec-bbf8-b1d1b1fea48e',
      },
    },
    label: 'Smokey1 Bear1 (smokey.bear@usda.gov)',
  },
];
