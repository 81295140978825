import { Component, Input } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, RowSelectionOptions } from 'ag-grid-community'; // Column Definition Type Interface
import { TextService } from '~app/services';
import { FilesizePipe } from '~app/utilities/filesize.pipe';

@Component({
  selector: 'ciao-attachment-grid',
  standalone: true,
  imports: [AgGridAngular],
  templateUrl: './attachment-grid.component.html',
  styleUrl: './attachment-grid.component.less',
})
export class AttachmentGridComponent {
  @Input() rowData;
  @Input() defaultColDef: ColDef;
  @Input() defaultRowSelection: RowSelectionOptions | 'single' | 'multiple';

  fileSizePipe = new FilesizePipe();

  attatchmentsColDefs: ColDef[] = [
    {
      field: 'uuid',
      hide: true,
    },
    { headerName: 'File Name', field: 'fileName' },
    { headerName: 'Type', field: 'extension' },
    {
      headerName: 'Size',
      field: 'fileSize',
      cellRenderer: (params) =>
        params.value ? `${this.fileSizePipe.transform(params.value)}` : '',
    },
    {
      headerName: 'Date Uploaded',
      field: 'createdAt',
      cellRenderer: (params) =>
        params.value ? `${this.formatDate(params.value)}` : '',
    },
  ];

  constructor(private textService: TextService) {}

  formatDate(date: Date | string) {
    return this.textService.formatSimpleDatetime(date);
  }
}
