<button
  mat-icon-button
  [matMenuTriggerFor]="rowOptionsMenu"
  [matMenuTriggerData]="{}"
  aria-label="Row Options"
  class="semantic-button"
  style="margin: auto; padding: auto"
>
  <span class="screenreader">Row Options</span>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #rowOptionsMenu>
  <ng-template matMenuContent let-id="id">
    <button mat-menu-item (click)="(null)">
      <mat-icon>edit</mat-icon>
      Edit
    </button>
    <button
      mat-menu-item
      [matMenuTriggerFor]=""
      [matMenuTriggerData]="{ id: id }"
    >
      <mat-icon>delete</mat-icon> Delete
    </button>
  </ng-template>
</mat-menu>
