import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '~app/components/shared/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FieldComponent } from './field/field.component';
import { DateTimeInputComponent } from './field/date-time-input/date-time-input.component';
import { FormFieldDateRangeComponent } from './field/date-range/date-range.component';
import { FormFieldDateInputComponent } from './field/date-input/date-input.component';
import { DropdownSubtypeDirective } from './field/dropdown-subtype.directive';
import { LatLongComponent } from './field/lat-long/lat-long.component';
import { CiaoSharedModule } from '../shared.module';
import { DateTimeCustomAccessorDirective } from './field/date-time-custom-accessor.directive';
import { MatAutocompleteCustomAccessorDirective } from './field/mat-autocomplete-custom-accessor.directive';
import { FieldErrorListComponent } from './field/field-error-list/field-error-list.component';
import { FormCheckboxGroupComponent } from './form-checkbox-group/form-checkbox-group.component';

const internalComponents = [
  FormFieldDateRangeComponent,
  FormFieldDateInputComponent,
  DateTimeInputComponent,
  LatLongComponent,
];
const externalComponents = [
  FieldComponent,
  DropdownSubtypeDirective,
  FormCheckboxGroupComponent,
];

@NgModule({
  declarations: [...internalComponents, ...externalComponents],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MaterialModule,
    NgbModule,
    CiaoSharedModule,
    FieldErrorListComponent,
    DateTimeCustomAccessorDirective,
    MatAutocompleteCustomAccessorDirective,
  ],
  exports: [...externalComponents, FieldErrorListComponent],
})
export class CiaoFormsModule {}
