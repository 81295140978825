import { CommonModule, Location } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OkayModalComponent } from '~app/components/shared/okay-modal/okay-modal.component';
import { CiaoSharedModule } from '~app/components/shared/shared.module';
import { TripAttributes } from '~app/models';

import { TripService } from '~app/services';
import { RolePermissionService } from '~app/services/role-permission.service';
import { OkayModalSectionComponent } from './okay-modal-section/okay-modal-section.component';
import { TripLogSectionComponent } from './trip-log-section/trip-log-section.component';
import { map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { TripPageDropdownOptionsService } from './trip-page-dropdown-options.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DateTimeCustomAccessorDirective } from '~app/components/shared/forms/field/date-time-custom-accessor.directive';
import { CrewGridComponent } from '../../system-admin/ag-grid/crew-grid/crew-grid.component';
import { EquipmentGridComponent } from '../../system-admin/ag-grid/equipment-grid/equipment-grid.component';
import { LocationGridComponent } from '../../system-admin/ag-grid/location-grid/location-grid.component';
import { AttachmentGridComponent } from '../../system-admin/ag-grid/attachment-grid/attachment-grid.component';

import { ColDef, RowSelectionOptions } from 'ag-grid-community'; // Column Definition Type Interface
import { TripMapComponent } from '../trip-summary/trip-map/trip-map.component';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialModule } from '~app/components/shared/material.module';

@Component({
  selector: 'ciao-trip-page-v3',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatIconModule,
    MaterialModule,
    ReactiveFormsModule,
    CiaoSharedModule,
    MatSelectModule,
    MatAutocompleteModule,
    DateTimeCustomAccessorDirective,
    TripMapComponent,
    OkayModalSectionComponent,
    TripLogSectionComponent,
    CrewGridComponent,
    EquipmentGridComponent,
    LocationGridComponent,
    AttachmentGridComponent,
  ],
  providers: [Location],
  templateUrl: './trip-page-v3.component.html',
  styleUrl: './trip-page-v3.component.less',
})
export class TripPageV3Component {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    public readonly tripService: TripService,
    private readonly rolePermissionService: RolePermissionService,
    private readonly toastr: ToastrService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dropdownOptionService: TripPageDropdownOptionsService
  ) {}

  @ViewChild('locationsGrid') locationsGrid: LocationGridComponent;

  subscriptions = new Subscription();

  readonly refresh$ = new BehaviorSubject<void>(null);
  readonly tripId$ = new BehaviorSubject<string>(null);
  readonly trip$ = new BehaviorSubject<TripAttributes>(null);

  readonly userGroupOptions$ = this.dropdownOptionService.userGroupOptions$;
  readonly stateOptions$ = this.dropdownOptionService.stateOptions$;

  readonly tripFromTripServiceSub = this.refresh$
    .pipe(
      switchMapTo(this.route.params),
      map((params) => params.id),
      tap((id) => console.log('Id', id)),
      tap(this.tripId$),
      switchMap((tripId) => this.tripService.findById(tripId)),
      // the BehaviorSubject can act as a list of functions that fits
      // perfectly with the tap/subscribe parameter of next/error/complete
      // We are piping the result of the switchMap into the trip$ behavior subject.
      // Simpler/Cleaner replacement for <div *ngIf="trip$ | async as trip"/>
      tap(this.trip$),
      tap((trip) => {
        console.log(trip);

        // To do: don't patch fields with modified values
        this.formGroup.patchValue(trip);
      })
    )
    .subscribe();

  get trip() {
    return this.trip$.value;
  }

  get showCheckedIn() {
    return this.trip.crewMembersProcessed.every(
      (crewMember) => crewMember?.crewMemberStatus === 'CheckedIn'
    );
  }

  get canChangeUserGroup() {
    return true;
    return this.trip$.value?.id === null;
  }
  get canEditTrip() {
    return this.trip$.value?.tripStatus !== 'Closed';
  }

  readonly formGroup = new FormGroup({
    userGroupId: new FormControl(''),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.tripFromTripServiceSub.unsubscribe();
  }

  clearChanges() {}

  /** Syntactic Sugar for `this.formGroup.controls[type].controls[index].controls[property]`*/
  getControl(type: string, index: number, property: string) {
    let control =
      this.formGroup.controls[type].controls[index].controls[property];
    if (!(control instanceof FormControl)) {
      throw new Error('Form Control not found');
    }
    return control;
  }

  debug(...args) {
    alert(...args);
  }

  // --------------------------------------AG GRID--------------------------------------------------------

  defaultColDef: ColDef = {
    flex: 1,
    cellStyle: (params) => {
      return { textAlign: 'left', cursor: 'pointer', color: '#2a5f04' };
    },
  };

  public defaultRowSelection: RowSelectionOptions | 'single' | 'multiple' = {
    mode: 'multiRow',
    checkboxes: true,
    enableClickSelection: true,
  };
}
