import { Component, Input } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, RowSelectionOptions } from 'ag-grid-community'; // Column Definition Type Interface

@Component({
  selector: 'ciao-equipment-grid',
  standalone: true,
  imports: [AgGridAngular],
  templateUrl: './equipment-grid.component.html',
  styleUrl: './equipment-grid.component.less',
})
export class EquipmentGridComponent {
  @Input() rowData;
  @Input() defaultColDef: ColDef;
  @Input() defaultRowSelection: RowSelectionOptions | 'single' | 'multiple';

  equipmentColDefs: ColDef[] = [
    {
      field: 'uuid',
      hide: true,
    },
    { headerName: 'Vehicles/Equipment', field: 'details', editable: true },
    { headerName: 'State', field: 'state.stateName', editable: true },
    { headerName: 'License #', field: 'license', editable: true },
    { headerName: 'Make', field: 'make', editable: true },
    { headerName: 'Model', field: 'model', editable: true },
  ];
}
