import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';
import { endWith, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigDataService {
  loaded$ = new AsyncSubject();
  configData = {
    api_url: '/ciao/v1',
    show_under_construction: false,
    features: {
      demo: {
        search_page: {
          supervisor_column: false,
        },
      },
    },
  };

  configData$ = this.loaded$.pipe(
    endWith(true),
    map((_) => this.configData),
    shareReplay()
  );

  apiUrl$ = this.configData$.pipe(map((data) => data.api_url));

  constructor() {}
}
