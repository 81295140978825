import { Injectable } from '@angular/core';
import { ApiService, Endpoint } from './api.service';
import {
  Observable,
  of,
  AsyncSubject,
  interval,
  combineLatest,
  fromEvent,
  BehaviorSubject,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  share,
  startWith,
  switchMap,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import mime from 'mime';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  //private endpoint: Endpoint<TripAttributes>;
  public readonly endpointName = 'files';

  constructor(
    private apiService: ApiService
  ) {
    //this.endpoint = apiService.endpoint(this.endpointName);
    // http://localhost:3033/api/v1/trips/f6667340-4377-4ce2-87ed-056fbf23565f/files/4b1cadf2-0960-40b1-8145-613574faa15e?download
  }



}
