<ag-grid-angular
  class="ag-theme-quartz"
  [rowData]="rowData"
  [defaultColDef]="defaultColDef"
  [rowSelection]="defaultRowSelection"
  [pinnedTopRowData]="pinnedTopRowData"
  [getRowStyle]="getRowStyle"
  [gridOptions]="gridOptions"
  (gridReady)="gridReady($event)"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (rowSelected)="handleRowSelection($event)"
  domLayout="autoHeight"
/>

<button
  class="float-right"
  *ngIf="showDeleteButton"
  aria-label="remove trip locations"
>
  <span class="screenreader">remove</span>
  <fa-icon
    (click)="deleteSelectedRows()"
    class="icon"
    [icon]="faDelete"
  ></fa-icon>
</button>
