import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { combineLatest, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigDataService } from './config-data.service';
import { WAIT_FOR_CONFIG_IGNORE_TOKEN } from '~app/guards/wait-for-config.interceptor';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(
    private readonly configDataService: ConfigDataService,
    private readonly http: HttpClient
  ) {}

  loadConfigs() {
    combineLatest([
      this.getConfigFromFile('default.json'),
      this.getConfigFromFile('local.json'),
    ])
      .pipe(
        tap((configs) => {
          this.addConfigs(configs);
          this.lock();
        })
      )
      .subscribe();
  }

  getConfigFromFile(fileName: string, mandatory: boolean = false) {
    const filePath = `./assets/config/${fileName}`;
    return this.http
      .get(filePath, {
        context: new HttpContext().set(WAIT_FOR_CONFIG_IGNORE_TOKEN, true),
      })
      .pipe(
        catchError((err, caught) => {
          if (mandatory) {
            console.error(err);
            console.warn(`File ${filePath} not found.  This file is required.`);
            throw err;
          } else {
            console.error(err);
            console.warn(`File ${filePath} not found.  Ignoring`);
            return of({});
          }
        })
      );
  }
  /**
   * Adds configs in the order in which they're specified.
   * Later elements outweigh earlier elements
   */
  addConfigs(configs: any[]): void {
    configs.forEach((config) => {
      this.addConfig(config);
    });
  }
  addConfig(config: any): void {
    if (this.configDataService.loaded$.closed) return;
    _.merge(this.configDataService.configData, config);
  }
  lock(): void {
    this.configDataService.loaded$.complete();
  }

  get configurationObject() {
    return _.merge({}, this.configDataService.configData);
  }

  /**
   *
   * @param configPath the nested object key, such as "api_url" or "somearray[3].name"
   */
  getConfig(configPath: string) {
    if (!this.hasConfig(configPath)) {
      throw new ReferenceError(
        `Cannot find configuration setting ${configPath}`
      );
    }
    return _.get(this.configDataService.configData, configPath);
  }

  /**
   *
   * @param configPath the nested object key, such as "api_url" or "somethings[3].name"
   */
  hasConfig(configPath: string) {
    return _.has(this.configDataService.configData, configPath);
  }

  get apiUrl(): string {
    return this.getConfig('api_url');
  }

  get baseUrl() {
    return this.getConfig('baseUrl');
  }
}
