import { Injectable } from '@angular/core';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { PersonService } from './person.service';
import { PersonAttributes } from '~app/models';

/** This class gives the ability to run common things  */
@Injectable({
  providedIn: 'root',
})
export class QACommandsService {
  constructor(
    private readonly authService: AuthService,
    private readonly personService: PersonService
  ) {}
  readonly allCommands = {
    list: () => this.listAllCommands(),
    resetUserConfigSettings: () => this.resetUserConfigSettings(),
  } as const;
  readonly descriptions = {
    list: 'List All Commands',
    resetUserConfigSettings:
      'Reset User Config Settings to what they were in Version 1.19',
  } as const;

  enableEnablingQACommands() {
    (window as any).enableQACommands = () => {
      (window as any).QACommands = this.allCommands;
    };
  }
  listAllCommands() {
    let keys = Object.keys(this.allCommands);
    let keysAndDescriptions = keys.map((key) =>
      this.descriptions[key] ? key + ': ' + this.descriptions[key] : key
    );
    console.log(keysAndDescriptions.join('\n'));
  }

  resetUserConfigSettings() {
    this.authService.currentUser$
      .pipe(
        take(1),
        map((user) => user.person),
        switchMap((person) =>
          this.personService.savePerson({
            id: person.id,
            configSettings: null,
          } as PersonAttributes)
        ),
        tap((person) => {
          console.log('Successfully Saved');
          console.log('Config Settings:', person.configSettings);
          console.log('Person Object:', person);
        })
      )
      .subscribe();
  }
}
