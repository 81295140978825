import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, share, shareReplay, switchMap } from 'rxjs/operators';
import { SelectOption } from '~app/components/shared/forms/field/types';
import { StateAttributes, CountyAttributes } from '~app/models';
import { LocationService } from '~app/services';
import { RolePermissionService } from '~app/services/role-permission.service';
import { UserGroupService } from '~app/services/user-group.service';

@Injectable({
  providedIn: 'root',
})
export class TripPageDropdownOptionsService {
  constructor(
    private readonly rolePermissionService: RolePermissionService,
    private readonly locationService: LocationService,
    private readonly userGroupService: UserGroupService
  ) {}

  readonly userGroupOptions$: Observable<SelectOption<string>[]> =
    this.userGroupService.allTeams$.pipe(
      switchMap((groups) =>
        this.rolePermissionService.searchMyUserGroupsByPermission({
          permissionIds: ['create_trips', 'edit_trip'],
          filterWithinUserGroups: groups,
        })
      ),
      this.userGroupService.op_sortGroups,
      map((userGroups) =>
        userGroups.map((userGroup) => ({
          value: userGroup.id,
          label: `${userGroup.labelPrefix}${userGroup.label}`,
        }))
      ),
      share()
    );

  readonly countyCodesAutocomplete$: Observable<string[]>;
  readonly stateOptions$: Observable<
    { value: StateAttributes; label: string }[]
  >;
  readonly countyOptions$: Observable<
    { value: CountyAttributes; label: string }[]
  >;

  crewOptions(userGroupId$: string | Observable<string>) {
    if (typeof userGroupId$ === 'string') {
      userGroupId$ = of(userGroupId$);
    }
    const crewMemberAndSupervisorList$ = userGroupId$.pipe(
      filter((x) => !!x),
      switchMap((userGroupId) =>
        this.userGroupService.getCrewMemberDropdownLists(userGroupId)
      )
    );
    const crewMemberOptions$ = crewMemberAndSupervisorList$.pipe(
      map((listByRoles) => listByRoles.crew_member),
      // Using instead of shareReplay(1) because we want these to reset on .unsubscribe
      share()
    );

    const supervisorOptions$ = crewMemberAndSupervisorList$.pipe(
      map((listByRoles) => listByRoles.supervisor),
      // Using instead of shareReplay(1) because we want these to reset on .unsubscribe
      shareReplay({ bufferSize: 1, refCount: true })
    );
    return {
      crewMemberOptions$,
      supervisorOptions$,
    };
  }
}
