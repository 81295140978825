import { Component, Input } from '@angular/core';
import * as _ from 'lodash-es';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ContactFormData,
  ConvertContactFormDataToSavableData,
  ConvertSavedContactDataToFormData,
  textNotificationOptions,
} from '~app/models/contactInfo';
import { uuid } from 'uuid';
import { MatIconModule } from '@angular/material/icon';
import { CiaoFormsModule } from '~app/components/shared/forms/forms.module';
import { FormsUtility } from '~app/utilities';
import { AssignedRole, PersonAttributes, UserAttributes } from '~app/models';
import { AuthService, AutocompleteService, PersonService } from '~app/services';
import { UserGroupService } from '~app/services/user-group.service';
import { Observable, Subscription, combineLatest, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { RolePermissionService } from '~app/services/role-permission.service';
import { UserGroupAttributes } from '~app/models/user-group';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { ToastrService } from 'ngx-toastr';
import { SelectOption } from '~app/components/shared/forms/field/types';
import { DatetimeService } from '~app/services/datetime.service';

const infoText = {
  GovernmentDevice: `Visible to all CIAO admin users.
Select the Text notifications checkbox to receive texts.`,
  Personal: `Visible to all CIAO admin users.
Select the Text notifications checkbox to receive texts.`,
  defaultPhone: `The number that auto-fills when user is added to a Trip.
Text notifications go to any phone that has texts enabled.`,
  defaultSupervisor: `If this person is on a Team with the user, they will be added automatically on a trip when the user is a Crew Member`,
} as const;

@Component({
  selector: 'ciao-user-profile-form',
  standalone: true,
  imports: [
    CiaoFormsModule,
    MatIconModule,
    CommonModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.less', '../shared.less'],
})
export class UserProfileFormComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly autoCompleteService: AutocompleteService,
    private readonly personService: PersonService,
    private readonly userGroupService: UserGroupService,
    private readonly rolesPermissionService: RolePermissionService,
    private readonly toastrService: ToastrService,
    private readonly dateTimeService: DatetimeService
  ) {}

  _personId: uuid = null;
  @Input() set personId(value: uuid) {
    this._personId = value;
    this.refreshPerson();
  }
  get personId() {
    return this._personId;
  }

  isSaving: boolean = false;

  readonly defaultData: FormData = {
    person: {
      id: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
    },
    users: [
      { email: '', id: '', notificationOptions: { defaultSet: false } },
      { email: '', id: '', notificationOptions: { defaultSet: false } },
    ],
    /** Deprecated: This formControl "regionRoles" will be removed when admin tab is established */
    regionRoles: [],
  };

  governmentControls = {
    phoneNumber: new UntypedFormControl(''),
    smsOptIn: new UntypedFormControl(false),
    smsNotifications: new UntypedFormGroup({
      beforeLate: new UntypedFormControl(false),
      exactlyWhenLate: new UntypedFormControl(false),
      repeatedAfterLate: new UntypedFormControl(false),
    }),
  };

  personalControls = {
    phoneNumber: new UntypedFormControl(''),
    smsOptIn: new UntypedFormControl(false),
    smsNotifications: new UntypedFormGroup({
      beforeLate: new UntypedFormControl(false),
      exactlyWhenLate: new UntypedFormControl(false),
      repeatedAfterLate: new UntypedFormControl(false),
    }),
  };
  formGroup = new FormGroup({
    person: new FormGroup({
      id: new FormControl(''),
      firstName: new FormControl('', [
        Validators.required,
        FormsUtility.isOverCharLimit,
      ]),
      lastName: new FormControl('', [
        Validators.required,
        FormsUtility.isOverCharLimit,
      ]),
      jobTitle: new FormControl('', [Validators.required]),
      configSettings: new FormGroup({
        clock: new FormGroup({
          timeZone: new FormControl(this.dateTimeService.initialTimezone, {
            nonNullable: true,
          }),
          hour12: new FormControl(false, { nonNullable: true }),
        }),
      }),
    }),
    governmentPhone: new FormGroup(this.governmentControls),
    personalPhone: new FormGroup(this.personalControls),
    defaultPhone: new FormControl(''),
    defaultPhoneIsPersonal: new FormControl(false),
    defaultPhoneIsGovernment: new FormControl(false),
    defaultSupervisorId: new FormControl(null),
    allTexts: new FormGroup({
      governmentPhone: new FormControl(false),
      personalPhone: new FormControl(false),
    }),
    users: new FormArray([
      new FormGroup({
        email: new FormControl('', [Validators.email]),
        id: new FormControl(''),
        notificationOptions: new FormGroup({
          defaultSet: new FormControl(false),
        }),
      }),
      new FormGroup({
        email: new FormControl('', [Validators.email]),
        id: new FormControl(''),
        notificationOptions: new FormGroup({
          defaultSet: new FormControl(false),
        }),
      }),
    ]),
    /** Deprecated: This formControl "regionRoles" will be removed when admin tab is established */
    regionRoles: new FormControl([], { nonNullable: true }),
  });

  // -------------- Content for details --------------- //
  regionOptions$: Observable<SelectOption[]>;
  jobOptions$: Observable<SelectOption[]>;
  clockHourOptions$: Observable<SelectOption<boolean>[]> =
    this.dateTimeService.getClockHourOptions$();
  timeZoneOptions$: Observable<SelectOption[]> =
    this.dateTimeService.getTimeZoneOptions$();

  /** Deprecated: This variable will be removed when admin tab is established */
  AssignRegionRoleSelectOptions$: Observable<
    {
      label: string;
      value: { UserGroupId: string; RoleId: string };
      disabled: boolean;
    }[]
  >;
  /** Deprecated: This variable will be removed when admin tab is established */
  InitialAssignedRoles$: Observable<AssignedRole[]> = of([]);
  /** Deprecated: This variable will be removed when admin tab is established */
  appliedPermissionsAssignAdminRoles$ = combineLatest([
    this.userGroupService.systemUserGroup$,
    this.userGroupService.allRegions$,
  ]).pipe(
    filter(([system, regions]) => Boolean(system && regions.length)),
    map(([system, regions]) => [system, ...regions]),
    switchMap((userGroups) =>
      this.rolesPermissionService.searchMyAppliedPermissions({
        permissionIds: ['assign_system_admin', 'assign_region_admin'],
        filterWithinUserGroups: userGroups,
      })
    )
  );

  // -------------- Content for contact --------------- //
  readonly defaultPhoneSelectOptions$ = of([
    { label: 'Personal Cell Phone', value: 'personal' },
    { label: 'Government Cell Phone', value: 'government' },
  ]);
  readonly defaultSupervisorSelectOptions$ = this.personService.allPeople.pipe(
    map((people) =>
      people
        .filter((person) => this.personId != person.id)
        .map((person) => ({
          value: person.id,
          label: person.displayNameDropdown,
        }))
    )
  );
  disablePersonalPhoneRadio;
  disableGovernmentPhoneRadio;
  textNotificationOptions = textNotificationOptions;
  readonly infoText = infoText;

  subscriptions = new Subscription();

  ngOnInit() {
    /** Deprecated: This method call will be removed when admin tab is established */
    this.intializeEnabledRegionRoleOptions();

    //Contact init
    this.intializeFormListeners();

    this.regionOptions$ = this.userGroupService.allRegions$.pipe(
      map((userGroups) =>
        userGroups.map((userGroup) => ({
          value: userGroup.id,
          label: userGroup.label,
        }))
      )
    );
    this.jobOptions$ = this.autoCompleteService.allJobTitles$.pipe(
      map((jobTitles) =>
        jobTitles.map((jobTitle) => ({
          value: jobTitle.titleName,
          label: jobTitle.titleName,
        }))
      )
    );

    this.subscriptions.add(
      this.formGroup.controls.person.controls.configSettings.valueChanges
        .pipe(
          tap(({ clock: { timeZone, hour12 } }) => {
            this.dateTimeService.hour12$.next(hour12);
            this.dateTimeService.timeZone$.next(timeZone);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.authService.loadCurrentUser();
  }
  getCustomTriggerFn(optionsSet: SelectOption<any>[]) {
    return (value) => optionsSet.find((opt) => opt.value === value)?.label;
  }
  /** Deprecated: This method will be removed when admin tab is established */
  intializeEnabledRegionRoleOptions() {
    let appliedPermissionsAssignAdminRoles$ = combineLatest([
      this.userGroupService.systemUserGroup$,
      this.userGroupService.allRegions$,
      this.userGroupService.allForestLabs$,
    ]).pipe(
      filter(([system, regions, forestLabs]) =>
        Boolean(system && regions.length)
      ),
      map(([system, regions, forestLabs]) => [
        system,
        ...regions,
        ...forestLabs,
      ]),
      switchMap((userGroups) =>
        this.rolesPermissionService.searchMyAppliedPermissions({
          permissionIds: ['assign_system_admin', 'assign_region_admin'],
          filterWithinUserGroups: userGroups,
        })
      )
    );

    /** Deprecated: This variable will be removed when admin tab is established */
    this.AssignRegionRoleSelectOptions$ = combineLatest([
      this.userGroupService.systemUserGroup$,
      this.userGroupService.allRegions$,
      this.userGroupService.allForestLabs$,
      appliedPermissionsAssignAdminRoles$,
    ]).pipe(
      map(([system, regions, forestLabs, myPermissions]) => {
        // for the record, this whole thing takes 1ms
        const unicodeSpace = '\u00A0';
        const unicodeTab = unicodeSpace.repeat(8);
        let idsOfGroupsICanAssign = {};
        // idsOfGroupsICanAssign Enables O(n+m) instead of O(n*m)
        myPermissions.forEach((perm) => {
          idsOfGroupsICanAssign[
            perm.UserGroupId + ' | ' + perm.AppliedPermissionId
          ] = true;
        });
        let processOptionForGroup = (group: UserGroupAttributes) => {
          const RoleId = {
            '': 'system_admin',
            System: 'system_admin',
            Region: 'region_admin',
            ForestLab: 'region_admin',
          }[group.tierLabel];
          const indent = group.tierLabel === 'ForestLab' ? 1 : 0;
          return {
            label:
              unicodeSpace.repeat(8 * indent) + group.labelPrefix + group.label,
            value: { UserGroupId: group.id, RoleId: RoleId },
            disabled: !idsOfGroupsICanAssign[`${group.id} | assign_${RoleId}`],
          };
        };
        let roleSelectOptions = [processOptionForGroup(system)];
        if (roleSelectOptions[0].disabled) {
          roleSelectOptions.pop();
        }
        regions.forEach((region) => {
          let regionOption = processOptionForGroup(region);
          let labs = region.children
            .filter((child) => child.tierLabel === 'ForestLab')
            .map((lab) => processOptionForGroup(lab))
            .filter((option) => !option.disabled);
          if (labs.length || !regionOption.disabled) {
            roleSelectOptions.push(regionOption, ...labs);
          }
        });
        return roleSelectOptions;
      })
    );
  }

  intializeFormListeners() {
    // When gov/personal phone number is not valid, clear values and disable checkboxes
    let govPhoneControl = this.formGroup.get('governmentPhone.phoneNumber');
    let govTextOptions = this.formGroup.get('governmentPhone.smsNotifications');
    let personalPhoneControl = this.formGroup.get('personalPhone.phoneNumber');
    let personalTextOptions = this.formGroup.get(
      'personalPhone.smsNotifications'
    );
    let radioBtn = this.formGroup.get('defaultPhone');

    this.subscriptions.add(
      govPhoneControl.valueChanges
        .pipe(
          tap((_value) => {
            if (!govPhoneControl.value) {
              // reset text options b/c no phone
              govTextOptions.patchValue({
                beforeLate: false,
                exactlyWhenLate: false,
                repeatedAfterLate: false,
              });
              // disable radio button and text options b/c no phone
              govTextOptions.disable();
              this.disableGovernmentPhoneRadio = true;
              if (personalPhoneControl.value && personalPhoneControl.valid) {
                // if personal phone has a value then make that the new default
                radioBtn.setValue('personal');
              }
            } else {
              govTextOptions.enable();
              this.disableGovernmentPhoneRadio = false;
              if (!personalPhoneControl.value || personalPhoneControl.invalid) {
                radioBtn.setValue('government');
              }
            }
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      personalPhoneControl.valueChanges
        .pipe(
          tap((_value) => {
            if (!personalPhoneControl.value) {
              personalTextOptions.patchValue({
                beforeLate: false,
                exactlyWhenLate: false,
                repeatedAfterLate: false,
              });
              // disable radio button and text options
              personalTextOptions.disable();
              this.disablePersonalPhoneRadio = true;
              if (govPhoneControl.value && govPhoneControl.valid) {
                radioBtn.setValue('government');
              }
            } else {
              personalTextOptions.enable();
              this.disablePersonalPhoneRadio = false;
              if (!govPhoneControl.value || govPhoneControl.invalid) {
                radioBtn.setValue('personal');
              }
            }
          })
        )
        .subscribe()
    );
  }

  refreshPerson() {
    this.formGroup.reset();
    if (!this._personId) {
      // this.resetForm();
      return;
    }
    let person$ = this.personService.findById(this._personId);

    let userGroup$ = person$.pipe(
      switchMap((person) => this.userGroupService.findById(person.mainGroupId))
    );

    /** Deprecated: This will be removed when admin tab is established */
    this.InitialAssignedRoles$ = this.rolesPermissionService
      .searchRoles({
        personIds: [this._personId],
        roleIds: ['system_admin', 'region_admin'],
      })
      .pipe(shareReplay(1));

    // ensure we are able to set the user group to the person obj before form binding
    /** Deprecated: This reference of will be removed when admin tab is established */
    let obs = combineLatest([person$, userGroup$, this.InitialAssignedRoles$]);

    let sub = obs
      .pipe(take(1))
      /** Deprecated: This reference to regionRoles will be removed when admin tab is established */
      .subscribe(([person, userGroup, regionRoles]) => {
        if (person) {
          person.mainGroup = userGroup;
        }

        let personForm = _.mergeWith(
          {},
          this.defaultData,
          /** Deprecated: This reference to regionRoles will be removed when admin tab is established */
          convertObjectsToData(person, null, regionRoles),
          (a, b) => (b === null ? a : undefined)
        );

        this.formGroup.patchValue(personForm);
        /** Deprecated: This setting of regionRoles will be removed when admin tab is established */
        this.formGroup.get('regionRoles').patchValue(regionRoles);
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
      });

    // Now for the contact info
    let contactInfo$ = this.personService.getContactInfo(this.personId);
    this.subscriptions.add(
      contactInfo$
        .pipe(
          map((rawinfo) => {
            let contactForm = ConvertSavedContactDataToFormData(rawinfo);
            return contactForm;
          }),
          tap((info) => {
            console.log(info);

            this.formGroup.patchValue(info);
          })
        )
        .subscribe()
    );

    this.subscriptions.add(sub);
  }

  /** Deprecated: This will be removed when admin tab is established */
  AssignRegionRoleCompare(
    c1: Partial<AssignedRole>,
    c2: Partial<AssignedRole>
  ) {
    return c1?.UserGroupId === c2?.UserGroupId && c1?.RoleId === c2?.RoleId;
  }

  /** Deprecated: This will be removed when admin tab is established */
  AssignRegionRoleCustomTriggerText(values: AssignedRole[]) {
    if (!(values instanceof Array)) {
      return;
    }
    let systemAdminCount = values.filter(
      (rr) => rr.RoleId === 'system_admin'
    ).length;
    let regionAdminCount = values.filter(
      (rr) => rr.RoleId === 'region_admin'
    ).length;
    return [
      systemAdminCount ? `System Admin` : '',
      regionAdminCount ? `${regionAdminCount} Admin Role(s)` : '',
    ]
      .filter((x) => x)
      .join(' + ');
  }

  updateProfile() {
    let data = this.formGroup.value;
    this.formGroup.patchValue(data);
    let attr = convertDataToAttr(data);
    let contactData = ConvertContactFormDataToSavableData(
      data as ContactFormData
    );
    if (this.isSaving) {
      throw new Error('How did we get here???');
    }
    this.isSaving = true;

    /** Deprecated: This return statement will be removed when admin tab is established (roles and permissions will not need to be saved here) */
    return this.personService
      .savePerson(attr.person, attr.users)
      .pipe(
        map((person) => person.id),
        switchMap((personId) =>
          combineLatest([
            this.InitialAssignedRoles$,
            this.AssignRegionRoleSelectOptions$,
            of(personId),
          ])
        ),
        take(1),
        map(([initialRoles, roleOptions, personId]) => {
          // Only look at enabled roles
          let enabledRegionRoles = roleOptions
            .filter((regionRole) => !regionRole.disabled)
            .map((opt) => opt.value);
          // Look at roles that the user changed
          let intendedRoles = this.formGroup.get('regionRoles').value as {
            UserGroupId: string;
            RoleId: string;
          }[];
          let changes = enabledRegionRoles.map((regionRole) => {
            let presentOnInitial = Boolean(
              initialRoles.find((initial) =>
                this.AssignRegionRoleCompare(initial, regionRole)
              )
            );
            let presentOnChanged = Boolean(
              intendedRoles.find((role) =>
                this.AssignRegionRoleCompare(role, regionRole)
              )
            );
            let change: 'Assign' | 'Unassign' | undefined;
            if (presentOnInitial && !presentOnChanged) {
              change = 'Unassign';
            } else if (!presentOnInitial && presentOnChanged) {
              change = 'Assign';
            } else if (!presentOnInitial && !presentOnChanged) {
              change = undefined;
            } else if (presentOnInitial && presentOnChanged) {
              change = undefined;
            }
            return {
              regionRole,
              presentOnInitial,
              presentOnChanged,
              change,
            };
          });
          return { changes: changes.filter((item) => item.change), personId };
        }),
        switchMap(({ changes, personId }) => {
          if (changes.length === 0) {
            return of(personId);
          }
          let assignRequests = changes.map((change) => {
            if (change.change === 'Assign') {
              return this.rolesPermissionService.assignRole$({
                PersonId: personId,
                UserGroupId: change.regionRole.UserGroupId,
                RoleId: change.regionRole.RoleId,
              });
            } else if (change.change === 'Unassign') {
              return this.rolesPermissionService.unassignRole$({
                PersonId: personId,
                UserGroupId: change.regionRole.UserGroupId,
                RoleId: change.regionRole.RoleId,
              });
            } else {
              return throwError('This code should never be reached.');
            }
          });
          return combineLatest(assignRequests).pipe(mapTo(personId));
        }),
        switchMap((personId) => {
          return this.personService
            .saveMyContactInfo(contactData, personId)
            .pipe(
              catchError((err) => {
                this.toastrService.error(
                  'There was an error saving your contact information.',
                  'Error'
                );
                throw err;
              })
            );
        }),
        tap(() => {
          this._personId
            ? this.toastrService.success(
                'User was updated successfully.',
                'Success'
              )
            : this.toastrService.success(
                'User was added successfully.',
                'Success'
              );
        }),
        catchError((err, caught) => {
          /**@todo - Standardize Error Messages From API to limit rewriting on frontend */
          let messageDetails = '';
          let messageTitle = 'Error';

          if (JSON.stringify(err).toLowerCase().includes('same email')) {
            messageTitle = 'Account already exists';
            messageDetails =
              'Sorry, an account already exists for this user. If you would like to add this email to this account, delete the existing account and try again.';
          }
          if (err?.error?.errors) {
            let message = '';
            let errors = err?.error?.errors;
            if (errors instanceof Array) {
              errors = errors.map((e) => {
                if (e.message && e.fields) {
                  return `${e.message}: ${JSON.stringify(e.fields)}`;
                } else if (e.message) {
                  return e.message;
                } else {
                  return JSON.stringify(e);
                }
              });
              message = errors.join(' - ');
            } else {
              message = errors.toString();
            }
            let action = this._personId ? 'updated' : 'created';
            messageDetails = `User was not ${action} successfully. Error: ${message}`;
          }
          if (!messageDetails) {
            let action = this._personId ? 'updated' : 'created';
            console.log('Developer: You should parse this error:', err);
            messageDetails = `User was not ${action} successfully. Error: ${err}`;
          }
          this.toastrService.error(messageDetails, messageTitle, {
            tapToDismiss: false,
          });
          console.error(err);
          // Don't throw.  Let it pass through.
          return of(null);
        }),
        tap(() => {
          this.authService.loadCurrentUser();
          this.refreshPerson();
          this.personService.refresh$.next();
          this.isSaving = false;
        })
      )
      .subscribe();
  }
}

function convertObjectsToData(
  person?: PersonAttributes,
  user?: UserAttributes,
  /** Deprecated: This will be removed when admin tab is established */
  regionRoles?: AssignedRole[]
): FormData {
  return {
    person: {
      id: person?.id || '',
      firstName: person?.firstName || '',
      lastName: person?.lastName || '',
      jobTitle: person?.jobTitle,
      configSettings: person?.configSettings,
    },
    users:
      person?.users?.map((user) => ({
        id: user.id,
        email: user.email,
        notificationOptions: user.notificationOptions,
      })) || [],

    /** Deprecated: This will be removed when admin tab is established */
    regionRoles: regionRoles ?? [],
  };
}

function convertDataToAttr(
  data: Partial<UserProfileFormComponent['formGroup']['value']>
): {
  person: PersonAttributes;
  users: UserAttributes[];
} {
  let { id, firstName, lastName, jobTitle, configSettings } = data.person;
  let person = {
    id: id || undefined,
    firstName: firstName,
    lastName: lastName,
    displayName: `${firstName} ${lastName}`,
    displayNameDropdown: `${firstName} ${lastName} (${data.users[0]?.email})`,
    // registration specific fields
    jobTitle: jobTitle,
    configSettings: configSettings,
  };
  let users = data.users
    .filter((user) => !!user.email)
    .map((user) => ({
      id: user.id,
      email: user.email,
      notificationOptions: { defaultSet: user.notificationOptions?.defaultSet },
    }));
  return { person, users };
}

interface FormData {
  person?: Partial<PersonAttributes>;
  users?: {
    id?: string;
    email?: string;
    notificationOptions?: { defaultSet?: boolean };
  }[];

  //contact
  // defaultPhoneIsPersonal?: string;
  // defaultPhoneIsGovernment?: boolean;

  /** Deprecated: This will be removed when admin tab is established */
  regionRoles?: AssignedRole[];
}
