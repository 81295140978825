import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { PlotAttributes, TripAttributes } from '~app/models';
import { TextService, TripService } from '~app/services';
import { AttachmentService } from '~app/services';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';

import * as leaflet from 'leaflet';
import toGeoJson from '@mapbox/togeojson';
import { HttpClient } from '@angular/common/http';
import { rawTestGPXstring } from './test-gpx-file';
import { rawTestGPXstring2 } from './test-gpx-file-2';
import { CommonModule } from '@angular/common';

class MapLayerData {
  id: number;
  name: string;
  featurelayer: any;
  allpoints: any;
  enabled: boolean = true;
}

class MapFileData {
  id: number;
  maplayers: MapLayerData[];
  filename: string;
  enabled: boolean = true;
}

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ciao-trip-map',
  templateUrl: './trip-map.component.html',
  styleUrls: ['./trip-map.component.less'],
})
export class TripMapComponent implements OnInit {
  tripID = '';
  trip$: Observable<TripAttributes> = this.getTripObservable();
  map: leaflet.Map;
  locations: any[] = null;
  mapicons: any;
  mapfeatures: any[];
  addPointLocationsEnabled: boolean = false;

  fakeLocationDataArray: any[] = [
    {
      id: '92223f20-8b86-43ec-a8a8-d6e8c351d042',
      order: 1,
      type: 'point',
      style: 'start',
      description: 'A',
      textCoords: '39.585249329829345, -119.85895156860353',
      countyCode: '26000',
      plotNumber: null,
    },
    {
      id: '8411b027-fdbb-4c1e-9b2e-f73637e4f734',
      order: 2,
      type: 'point',
      style: 'waypoint',
      description: 'B',
      textCoords: '39.577840384117664, -119.85242843627931',
      countyCode: '26000',
      plotNumber: null,
    },
    {
      id: '7d3c459b-8568-4f0a-abd7-8ff8088c6993',
      order: 3,
      type: 'point',
      style: 'waypoint',
      description: 'C',
      textCoords: '39.57135690690458, -119.86392974853517',
      countyCode: '26000',
      plotNumber: null,
    },
    {
      id: 'b966e193-e9f1-474b-9ab1-1dca40920b22',
      order: 4,
      type: 'point',
      style: 'waypoint',
      description: 'D',
      textCoords: '39.57678189864509, -119.88315582275392',
      countyCode: '26000',
      plotNumber: null,
    },
    {
      id: '0ccc5c96-e11f-4afc-8042-8ba568546b14',
      order: 5,
      type: 'point',
      style: 'finish',
      description: 'E',
      textCoords: '39.58789518991517, -119.87972259521486',
      countyCode: '26000',
      plotNumber: null,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public tripService: TripService,
    private attachmentService: AttachmentService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.trip$
      .pipe(
        delay(0),
        tap((trip) => this.loadBaseMap(trip))
      )
      .subscribe();
  }

  private loadBaseMap(trip: any): void {
    console.log('loadBaseMap got trip: ', trip);
    let lat = 38.5781,
      long = -121.4944;
    this.map = leaflet.map('map').setView([lat, long], 2);
    leaflet
      .tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        maxZoom: 18,
      })
      .addTo(this.map);

    let self = this;
    this.map.on('click', function (e) {
      var coord = e.latlng;
      self.addNewPointLocationToData(trip, coord);
    });

    this.refreshMapPointDisplay(trip);
    this.reframeMap();
  }

  public toggleAllowingAddingLocations() {
    this.addPointLocationsEnabled = !this.addPointLocationsEnabled;
    console.log(
      'this.addPointLocationsEnabled: ',
      this.addPointLocationsEnabled
    );
  }

  private addNewPointLocationToData(trip: any, coord) {
    console.log('You clicked the map at coord: ', coord);
    if (!this.addPointLocationsEnabled) {
      console.log('adding points is not enabled, tap the button');
      return;
    }

    // find position to add this to the array.
    // im going with the simple idea for now, just add it to the end, change the previous finish point to a waypoint, make this the new finish

    // so, find the last point location currently, we'll take cues from there for description and order
    let lastlocation =
      this.fakeLocationDataArray[this.fakeLocationDataArray.length - 1];
    let neworder = lastlocation.order + 1;

    // this is kind of lame, and only goes up to 26.
    let letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let letterindex = letters.indexOf(lastlocation.description);
    let newletter = '?';
    if (letterindex < letters.length - 1) {
      newletter = letters[letterindex + 1];
    }

    lastlocation.style = 'waypoint'; // now this new one will be the finish line

    let pointLocationData = {
      id: crypto.randomUUID(),
      order: neworder,
      type: 'point',
      style: 'finish',
      description: newletter,
      textCoords: `${coord.lat}, ${coord.lng}`,
    };
    this.fakeLocationDataArray.push(pointLocationData);
    this.refreshMapPointDisplay(trip);
  }

  // called to add one marker on the map, this doesn't add another point location to the data, just called when the map is being rendered
  private addPointLocationToMap(location: any): void {
    if (this.mapicons == null) {
      let starticon = leaflet.icon({
        iconUrl: 'assets/img/icons/map-markers/green.png',
        iconSize: [30, 30], // size of the icon
        iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -30], // point from which the popup should open relative to the iconAnchor
      });
      let waypointicon = leaflet.icon({
        iconUrl: 'assets/img/icons/map-markers/blue.png',
        iconSize: [30, 30], // size of the icon
        iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -30], // point from which the popup should open relative to the iconAnchor
      });
      let finishicon = leaflet.icon({
        iconUrl: 'assets/img/icons/map-markers/flag.png',
        iconSize: [30, 30], // size of the icon
        iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -30], // point from which the popup should open relative to the iconAnchor
      });

      this.mapicons = { starticon, waypointicon, finishicon };
    }

    let icon = null;
    if (location.style === 'start') {
      icon = this.mapicons.starticon;
    } else if (location.style === 'waypoint') {
      icon = this.mapicons.waypointicon;
    } else if (location.style === 'finish') {
      icon = this.mapicons.finishicon;
    }

    let lat = location.coords.lat;
    let long = location.coords.long;
    var marker = leaflet.marker([lat, long], { icon: icon }).addTo(this.map);

    // point popup
    let markup = `<span style="font-size:1.2em; font-weight:bold;">Location ${location.description}</span><hr>Coordinates: ${lat}, ${long}<br><br>`;
    markup += `<input type="button" value="I'm Okay" `;
    markup += ` style="background-color: #7C4D38; color: white; border: 0px; border-radius: 10px; padding:10px; margin-left:100px; font-weight: bold;"`;
    markup += ` onclick="document.querySelector('.imOkBridgeHref-${location.id}').click();" >`;
    marker.bindPopup(markup);

    if (this.mapfeatures == null) {
      this.mapfeatures = [];
    }
    this.mapfeatures.push(marker);
  }

  // leaving this hook here, when they click on the "im ok" button in a marker hover-over, this function ultimately gets called.
  // replace this code with something to actually check in "im ok" at the given location.
  logImOk(event, location: any): void {
    console.log('im ok: ', location);
    alert('im ok at point ' + location.description);
  }

  // displaying a line on the map between all the point locations
  private addLineForPathPoints(locations: any): void {
    if (!locations) {
      return;
    }

    var array = [];
    locations.forEach((location) => {
      array.push(new leaflet.LatLng(location.coords.lat, location.coords.long));
    });
    var polyline = leaflet.polyline(array, {
      color: 'blue',
      weight: 3,
      opacity: 0.5,
      smoothFactor: 1,
    });
    polyline.addTo(this.map);

    if (this.mapfeatures == null) {
      this.mapfeatures = [];
    }
    this.mapfeatures.push(polyline);
  }

  // turn the trip locations data into "point locations", filter by type, sort, augment.
  private makePointLocations(trip: any): void {
    // find all the locations on the map that actually seem to represent a point.
    // NOTE im replacing this with some component-internal data for the moment.
    // if (trip.locations?.length > 0) { // switch back to this line when there is real data storage for this
    if (this.fakeLocationDataArray?.length > 0) {
      let locations = this._clone(this.fakeLocationDataArray);
      locations = locations.filter((loc) => loc.type === 'point');
      locations.sort((a, b) => a.order - b.order);

      for (let location of locations) {
        try {
          let parsed = location.textCoords?.split(', ');
          let lat = parseFloat(parsed[0]);
          let long = parseFloat(parsed[1]);
          location.coords = { lat, long };
          location.enabled = true;
        } catch (exception) {
          // whatever
        }
      }

      this.locations = locations;
    }
  }

  // just delete everything from the map
  private clearMapFeatures(): void {
    if (!this.mapfeatures) {
      return;
    }
    for (let feature of this.mapfeatures) {
      this.map.removeLayer(feature);
    }
  }

  // once the trips location data is available, and the web map has been created on the screen,
  // this is called to add map markers for each location and draw a line between them.
  // this also augments the point location data a little and saves it as a separate collection in the angular component.
  private refreshMapPointDisplay(trip: any): void {
    this.clearMapFeatures();
    this.makePointLocations(trip);
    for (let location of this.locations) {
      this.addPointLocationToMap(location);
    }
    this.addLineForPathPoints(this.locations);
  }

  // find all the points that are visible on the map and re-center the map on them with a little buffer
  private reframeMap() {
    // find all points from all visible features on the map, recalculate extents, zoom/flyto new optimal extents/zoomlevel

    let allvisiblepoints = [];
    for (let i = 0; i < this.locations.length; i++) {
      let location = this.locations[i];
      if (!location.enabled) {
        continue;
      }
      allvisiblepoints.push([location.coords.lat, location.coords.long]);
    }

    if (allvisiblepoints.length > 0) {
      let bounds = new leaflet.LatLngBounds(allvisiblepoints);
      this.map.fitBounds(bounds);
    }
  }

  private _clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  getTripObservable() {
    return this.route.params.pipe(
      map((value) => value.id),
      tap((tripId) => (this.tripID = tripId)),
      switchMap((tripId) =>
        tripId
          ? this.tripService.findById(tripId)
          : of(this.tripService.getBlankTripForm())
      ),
      map((trip) => {
        // sort the okays with latest on top
        trip.okays = trip.okays?.sort((a, b) =>
          b.recordedAt > a.recordedAt ? 1 : b.recordedAt < a.recordedAt ? -1 : 0
        );
        return trip;
      }),
      shareReplay(1)
    );
  }
}
