<ng-template #matLabelTemplate>
  <ng-container *ngIf="!agGridContext">
    <div class="label">
      <label mat-label [attr.fieldtype]="type" [attr.for]="inputId">
        <div class="field-title">
          {{ label }}
          <span
            class="required-star"
            *ngIf="required || requiredProxy"
            aria-label="Required"
            >*</span
          >
        </div>
      </label>
      <ciao-tool-tip
        *ngIf="toolTip"
        class="tool-tip"
        [title]="toolTipTitle ?? label"
        [message]="toolTip"
      ></ciao-tool-tip>
    </div>

    <p class="help-text" *ngIf="helpText">
      {{ helpText }}
    </p>
  </ng-container>
</ng-template>

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'checkbox'">
    <mat-checkbox
      [formControl]="formControlInputAsFormControl"
      [required]="required"
    >
      {{ label }}
    </mat-checkbox>
    <ciao-tool-tip
      *ngIf="toolTip"
      class="checkbox-tool-tip"
      [title]="label ?? toolTipTitle"
      [message]="toolTip"
    ></ciao-tool-tip>
  </ng-container>
  <ng-container *ngSwitchCase="'radio'">
    <mat-radio-group>
      <mat-radio-button
        [required]="required"
        [value]="radioValue"
        [name]="'btn-group'"
      >
        {{ label }}
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>
  <ng-container *ngSwitchCase="'toggle'">
    <mat-slide-toggle
      [id]="inputId"
      [aria-label]="label"
      [formControl]="formControlInputAsFormControl"
      [required]="required"
    >
      {{ label }}
    </mat-slide-toggle>
  </ng-container>

  <ng-container *ngSwitchCase="'dropdown'">
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <mat-form-field>
      <mat-select
        [id]="inputId"
        [aria-label]="label"
        [placeholder]="placeholder"
        [formControl]="formControlInputAsFormControl"
        [required]="required"
      >
        <mat-select-trigger *ngIf="customTrigger">
          {{ customTrigger(formControlInput.value) }}
        </mat-select-trigger>
        <ng-template ngFor let-item [ngForOf]="selectOptions$ | async">
          <mat-option [value]="item.value" [disabled]="item.disabled">
            @if (!item.labelHtml) {
            <div>
              {{ item.label }}
            </div>
            } @else {
            <div
              class="fullwidth"
              *ngIf="item.labelHtml"
              [innerHtml]="item.labelHtml"
            ></div>
            }
          </mat-option>
        </ng-template>
      </mat-select>
      <mat-icon matSuffix>expand_more</mat-icon>
    </mat-form-field>
    <!-- Need to fix some Dropdown Stuff Later.  Leave these in for ease of debugging. -->
    <!-- <pre><code>{{formControlInput.value | json}}</code></pre> -->
  </ng-container>
  <ng-container *ngSwitchCase="'combobox'">
    <ng-container
      *ngIf="label"
      [ngTemplateOutlet]="matLabelTemplate"
    ></ng-container>
    <mat-form-field
      [ngClass]="{ whiteBackground: agGridContext, noMargin: agGridContext }"
    >
      <input
        #customAutocomplete="customAutocomplete"
        type="text"
        matInput
        [id]="inputId"
        [title]="label"
        [placeholder]="placeholder"
        [formControl]="formControlInputAsFormControl"
        [ciaoMatAutocompleteCustomAccessor]="matAutoComplete"
        [compareWith]="compareWith"
        [options$]="selectOptions$"
      />
      <mat-icon matSuffix>expand_more</mat-icon>

      <mat-autocomplete
        #matAutoComplete
        [displayWith]="customAutocomplete.displayWith"
        (optionSelected)="onChangeClick($event)"
      >
        <ng-container
          *ngIf="customAutocomplete.filteredOptions$ | async as filteredOptions"
        >
          <ng-container
            *ngIf="filteredOptions.length <= 25; else virtualScroll"
          >
            <mat-option
              *ngFor="let item of filteredOptions"
              [value]="item"
              [disabled]="item.disabled"
            >
              {{ item.label }}
            </mat-option>
          </ng-container>
          <ng-template #virtualScroll>
            <cdk-virtual-scroll-viewport
              itemSize="48"
              minBufferPx="288"
              maxBufferPx="864"
              [ngStyle]="{ height: '240px' }"
            >
              <mat-option
                *cdkVirtualFor="let item of filteredOptions"
                [value]="item"
                [disabled]="item.disabled"
              >
                {{ item.label }}
              </mat-option>
            </cdk-virtual-scroll-viewport>
          </ng-template>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
    <!-- Need to fix some Dropdown Stuff Later.  Leave these in for ease of debugging. -->
    <!-- <pre><code>{{formControlInput.value | json}}</code></pre> -->
  </ng-container>
  <ng-container *ngSwitchCase="'multiselect'">
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <mat-form-field
      class="slim"
      appearance="outline"
      [style.width]="width ? '100%' : ''"
    >
      <mat-select
        [id]="inputId"
        [aria-label]="label"
        multiple="true"
        [compareWith]="compareWith"
        [formControl]="formControlInputAsFormControl"
        [required]="required"
        [placeholder]="placeholder"
      >
        <mat-select-trigger *ngIf="customTrigger">
          {{ customTrigger(formControlInput.value) }}
        </mat-select-trigger>
        <ng-template ngFor let-item [ngForOf]="selectOptions$ | async">
          <mat-option [value]="item.value" [disabled]="item.disabled">
            {{ item.label }}
          </mat-option>
        </ng-template>
      </mat-select>
      <mat-icon matSuffix>expand_more</mat-icon>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'date'">
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <ciao-form-field-date-input
      [id]="inputId"
      [label]="label"
      [formControlInput]="formControlInputAsFormControl"
    ></ciao-form-field-date-input>
  </ng-container>

  <ng-container *ngSwitchCase="'dateRange'">
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <ciao-form-field-date-range
      [id]="inputId"
      [label]="label"
      [required]="required"
      [formControlInput]="formControlInputAsFormControl"
    ></ciao-form-field-date-range>
  </ng-container>

  <ng-container *ngSwitchCase="'datetime'">
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <mat-form-field>
      <input
        #datetimeinput
        [id]="inputId"
        [title]="label"
        matInput
        [required]="required"
        class="datetime-local"
        type="datetime-local"
        [formControl]="formControlInputAsFormControl"
        ciaoDateTimeCustomAccessor
      />
      <button
        class="semantic-button"
        (click)="datetimeinput.showPicker()"
        matSuffix
      >
        <mat-icon small-icon>calendar_today</mat-icon>
        <span class="screenreader">Open Calendar</span>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'textarea'">
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <mat-form-field>
      <textarea
        [id]="inputId"
        [title]="label"
        matInput
        [formControl]="formControlInputAsFormControl"
        [required]="required"
        [placeholder]="placeholder"
      ></textarea>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'latlong'">
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <mat-form-field>
      <ciao-form-field-lat-long
        [id]="inputId"
        #latLongField
        [formControl]="formControlInputAsFormControl"
        [required]="required"
      ></ciao-form-field-lat-long>
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="'number'">
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <mat-form-field>
      <input
        matInput
        [id]="inputId"
        [title]="label"
        [placeholder]="placeholder"
        [autocomplete]="'ciao-no-autocomplete'"
        type="number"
        [min]="min"
        [max]="max"
        [step]="step"
        [formControl]="formControlInputAsFormControl"
        [required]="required"
      />
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="'phoneNumber'">
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <mat-form-field>
      <input
        matInput
        [id]="inputId"
        [title]="label"
        [placeholder]="placeholder"
        [autocomplete]="'ciao-no-autocomplete'"
        type="text"
        [formControl]="formControlInputAsFormControl"
        [required]="required"
      />
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <!--ngSwitchCase 'text' or 'input'-->
    <ng-container [ngTemplateOutlet]="matLabelTemplate"></ng-container>
    <mat-form-field>
      <input
        matInput
        [id]="inputId"
        [title]="label"
        [placeholder]="placeholder"
        [autocomplete]="'ciao-no-autocomplete'"
        type="text"
        [formControl]="formControlInputAsFormControl"
        [required]="required"
        [placeholder]="placeholder"
      />
    </mat-form-field>
  </ng-container>
</ng-container>
<div class="subtext" *ngIf="subtext">{{ subtext }}</div>
<ciao-field-error-list [control]="formControlInput" />
