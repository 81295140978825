import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SelectOption } from '~app/components/shared/forms/field/types';
import { AuthDataService } from './auth-data.service';
import { americanCanonTimeZones, timezoneMap } from './datetime.constants';

@Injectable({
  providedIn: 'root',
})
export class DatetimeService {
  constructor(
    private readonly authDataService: AuthDataService,
    @Inject(LOCALE_ID) private readonly _locale: string
  ) {
    this.authDataService.currentUser$
      .pipe(
        map(
          (user) =>
            user?.person?.configSettings ?? {
              clock: this.getDefaultDateTimeSettingsFromBrowser(),
            }
        ),
        tap((settings) => {
          this.hour12$.next(settings.clock?.hour12);
          this.timeZone$.next(settings.clock?.timeZone);
        })
      )
      .subscribe();
  }

  readonly hour12$ = new BehaviorSubject(true);
  readonly timeZone$ = new BehaviorSubject('America/Chicago');

  readonly browserDefaults = this.getDefaultDateTimeSettingsFromBrowser();

  /** Get Initial Timezone from Browser */
  get initialTimezone() {
    return window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  extractDate(value: Date | string) {
    if (!value) {
      return null;
    }
    if (typeof value === 'string' && value.length === 10) {
      value += 'T00:00';
    }
    if (!(value instanceof Date)) {
      value = new Date(value);
    }

    return value;
  }

  /**
   * Get the time part of the datetime string
   */
  getTimePart(input: {
    date: Date;
    /** Defaults to dateTimeService.hour12$.value */
    hour12?: boolean;
    /** Defaults to dateTimeService.timeZone$.value */
    timeZone?: string;
    style: 'readable' | 'filter' | 'simple';
  }) {
    // Note: As stated in #1845, we're undoing any frontend
    // changes for showing datetime based on preference
    // If we go back to letting users' preference affect the frontend,
    //  then change this to use `input.hour12 ?? this.hour12$.value` etc
    input.hour12 = input.hour12 ?? this.browserDefaults.hour12;
    input.timeZone = input.timeZone ?? this.browserDefaults.timeZone;
    let options: Intl.DateTimeFormatOptions = {
      hour12: input.hour12,
      timeZone: input.timeZone,
      hour: input.hour12 ? 'numeric' : '2-digit',
      minute: '2-digit',
    };
    if (['simple', 'readable'].includes(input.style)) {
      options.timeZoneName = 'short';
    }
    return input.date.toLocaleTimeString('en-US', options);
  }

  /**
   * Get the date part of the datetime string
   */
  getDatePart(input: {
    date: Date;
    /** Defaults to dateTimeService.timeZone$.value */
    timeZone?: string;
    style: 'readable' | 'filter' | 'simple';
  }) {
    if (!input.timeZone) {
      input.timeZone = this.timeZone$.value;
    }
    let options: Intl.DateTimeFormatOptions = {
      timeZone: input.timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    if (input.style === 'simple') {
      options.day = '2-digit';
    }
    if (input.style === 'filter') {
      options.weekday = 'long';
      options.month = 'long';
      options.day = '2-digit';
    }

    return input.date.toLocaleDateString('en-US', options);
  }

  /** Get List of CIAO's Clock Hour Styles, as a Select Option array */
  getClockHourOptions$(
    resetWhen$ = of(null)
  ): Observable<SelectOption<boolean>[]> {
    let hourOptions = [
      { label: 'Standard Clock', value: true },
      { label: '24-Hour Clock', value: false },
    ];
    let date = new Date();

    return combineLatest([this.timeZone$, resetWhen$]).pipe(
      map(([timeZone, _]) =>
        hourOptions.map(({ label, value }) => {
          let time = this.getTimePart({
            date,
            hour12: value,
            timeZone: timeZone,
            style: 'readable',
          });
          return {
            label,
            value,
            labelHtml: label + `<div class='pull-right'>${time}</div>`,
          };
        })
      )
    );
  }

  /** Get List of CIAO's Timezones, as a Select Option array */
  getTimeZoneOptions$(resetWhen$ = of(null)): Observable<SelectOption[]> {
    let date = new Date();

    return combineLatest([this.hour12$, resetWhen$]).pipe(
      map(([hour12, _]) =>
        americanCanonTimeZones.map((tz) => {
          let timeDisplay = this.getTimePart({
            date,
            hour12: hour12,
            timeZone: tz.timeZone,
            style: 'readable',
          });
          return {
            value: tz.timeZone,
            label: tz.label,
            labelHtml:
              tz.label + `<div class='pull-right'>${timeDisplay}</div>`,
          };
        })
      )
    );
  }
  getDefaultDateTimeSettingsFromBrowser() {
    const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const mainTimeZoneId = timezoneMap[timeZoneId] ?? 'America/Chicago';
    const hour12 = this._locale === 'en-US';
    return {
      timeZone: mainTimeZoneId,
      hour12: hour12,
    };
  }
}
