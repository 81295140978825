import { Component, Input } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  RowSelectionOptions,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ValueFormatterParams,
  CellEditingStoppedEvent,
  RowClassParams,
  CellEditingStartedEvent,
  GridOptions,
} from 'ag-grid-community'; // Column Definition Type Interface
import { CrewCellRendererComponent } from './crew-cell-renderer/crew-cell-renderer.component';
import { CrewCellEditorComponent } from './crew-cell-editor/crew-cell-editor.component';
import { Observable } from 'rxjs';
import { RowOptionsComponent } from '~app/components/views/trip/trip-page-v3/row-options/row-options.component';

@Component({
  selector: 'ciao-crew-grid',
  standalone: true,
  imports: [AgGridAngular],
  templateUrl: './crew-grid.component.html',
  styleUrl: './crew-grid.component.less',
})
export class CrewGridComponent {
  @Input() rowData;
  @Input() defaultColDef: ColDef;
  @Input() defaultRowSelection: RowSelectionOptions | 'single' | 'multiple';

  obs = new Observable();

  //cellRendererSelector might be good to use when crew is checked in (conditional cell renderer)

  gridApi;

  crewColDefs: ColDef[] = [
    {
      field: 'uuid',
      hide: true,
    },
    {
      headerName: 'Crew Member',
      field: 'crewMember',
      editable: true,
      cellDataType: 'person',
      cellStyle: { width: 'fit-content' },
      cellRenderer: CrewCellRendererComponent,
      cellRendererParams: {
        obs: this.obs,
      },
      cellEditor: CrewCellEditorComponent,
      cellEditorPopup: true,
      cellEditorPopupPosition: 'over',
      // this will change to be an actual list but for not the editor is getting a random list
      cellEditorParams: { crewOptions: ['Alice', 'Bob'] },
      // needed to fix sort when using complex objects
      comparator: (a, b) => {
        const displayNameA = a ? String(a?.displayName) : '';
        const displayNameB = b ? String(b?.displayName) : '';
        return displayNameA.localeCompare(displayNameB);
      },
    },
    {
      headerName: 'Supervisor',
      field: 'supervisor',
      editable: true,
      cellDataType: 'person',
      cellRenderer: CrewCellRendererComponent,
      cellRendererParams: {
        obs: this.obs,
      },
      cellEditor: CrewCellEditorComponent,
      cellEditorPopup: true,
      cellEditorPopupPosition: 'over',
      // this will change to be an actual list but for not the editor is getting a random list
      cellEditorParams: { crewOptions: ['Alice', 'Bob'] },
      // needed to fix sort when using complex objects
      comparator: (a, b) => {
        const displayNameA = a ? String(a?.displayName) : '';
        const displayNameB = b ? String(b?.displayName) : '';
        return displayNameA.localeCompare(displayNameB);
      },
    },

    {
      headerName: 'Sat Phone',
      field: 'satPhone',
      editable: true,
      cellStyle: { width: 'fit-content' },
    },
    {
      headerName: 'Sat Comm',
      field: 'satLocatorId',
      editable: true,
    },
    {
      headerName: 'Other Notes',
      field: 'notes',
      editable: true,
    },
    {
      field: '',
      pinned: 'right',
      lockPinned: true,
      width: 50,
      sortable: false,
      cellStyle: { alignContent: 'center' },
      resizable: false,
      cellRenderer: RowOptionsComponent,
    },
  ];

  gridOptions: GridOptions = {
    dataTypeDefinitions: {
      person: {
        extendsDataType: 'object',
        baseDataType: 'object',
      },
    },
    // define cols here to access custom object defs
    columnDefs: this.crewColDefs,
  };

  ngOninit() {}

  gridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {}
  onCellEditingStopped(event: CellEditingStoppedEvent) {}
}
