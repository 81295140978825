<ciao-okay-modal
  [disabled]="!canOkay(trip, 'checkOut')"
  okayType="checkOut"
  class="btn"
  [tripSummaryContext]="true"
  buttonClass="btn-action-solid"
  [trip]="trip"
></ciao-okay-modal>
<ciao-okay-modal
  [disabled]="!canOkay(trip, 'okay')"
  okayType="okay"
  class="btn"
  [tripSummaryContext]="true"
  buttonClass="btn-action-solid"
  [trip]="trip"
></ciao-okay-modal>
<ciao-okay-modal
  [disabled]="!canOkay(trip, 'checkIn')"
  okayType="checkIn"
  class="btn"
  [tripSummaryContext]="true"
  buttonClass="btn-action-solid"
  [trip]="trip"
></ciao-okay-modal>
