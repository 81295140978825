<ag-grid-angular
  class="ag-theme-quartz"
  [rowData]="rowData"
  [defaultColDef]="defaultColDef"
  [rowSelection]="defaultRowSelection"
  (gridReady)="gridReady($event)"
  [gridOptions]="gridOptions"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (cellEditingStarted)="onCellEditingStarted($event)"
  domLayout="autoHeight"
/>
