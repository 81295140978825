<h2 class="title">Trip Log</h2>
<p class="margin-left" *ngIf="trip?.okays?.length < 1">
  Trip actions will appear here.
</p>
<div class="item" *ngFor="let okay of trip?.okays">
  <div class="status" tabindex="0">
    <p>
      <span> Type</span><br />
      {{ getReadableOkayType(okay) }}
    </p>
    <p>
      <span>Recorded By</span><br />{{ okay?.recordedBy?.person?.displayName }}
    </p>
    <p>
      <span>Actual Time/Date</span><br />
      {{ formatDate(okay?.createdAt) }}
    </p>
    <p>
      <span>Entered Time/Date</span><br />
      {{ formatDate(okay?.timeOkay) }}
    </p>
    <p *ngIf="okay?.type === 'checkIn'">
      <span>Crew Member(s)</span><br />
      <ng-container *ngFor="let crew of okay.crewMembersSelected">
        {{ crew.displayName }}<br />
      </ng-container>
    </p>
    <p *ngIf="okay?.notes">
      <span>Notes</span><br />
      {{ okay?.notes }}
    </p>
  </div>
  <hr />
</div>
