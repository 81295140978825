import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, combineLatest, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { AuthDataService } from '../services/auth-data.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly authDataService: AuthDataService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let catchError401 = this.catchError401;
    if (request.url.endsWith('/user-groups')) {
      catchError401 = this.catchError401_AuthWithoutUser;
    }
    return next.handle(request).pipe(catchError401);
  }

  catchError401 = catchError((err, caught: Observable<HttpEvent<any>>) => {
    if (err?.status === 401) {
      this.authDataService._needsUpdate$.next();
      return this.authDataService._foundUser$.pipe(
        take(1),
        switchMap(() => caught)
      );
    } else {
      return throwError(err);
    }
  });
  catchError401_AuthWithoutUser = catchError(
    (err, caught: Observable<HttpEvent<any>>) => {
      if (err?.status === 401) {
        this.authDataService._needsUpdate$.next();
        return combineLatest([
          this.authDataService.oAuthInfo$,
          this.authDataService.currentUser$,
        ]).pipe(
          tap((values) => console.log('Hello', values)),
          filter(([oAuthInfo, userInfo]) => !!(oAuthInfo || userInfo)),
          take(1),
          switchMap(() => caught)
        );
      } else {
        return throwError(err);
      }
    }
  );
}
