import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import type { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'ciao-crew-cell-renderer',
  standalone: true,
  imports: [],
  templateUrl: './crew-cell-renderer.component.html',
  styleUrl: './crew-cell-renderer.component.less',
})
export class CrewCellRendererComponent implements ICellRendererAngularComp {
  params;
  crew;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setCrew(params);
  }

  private setCrew(params: ICellRendererParams) {
    this.crew = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setCrew(params);
    return true;
  }
}
