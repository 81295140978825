/** Filtered List of Timezones, specific to CIAO */
export const americanCanonTimeZones = [
  {
    timeZone: 'Pacific/Honolulu',
    label: 'Hawaii-Aleutian Standard Time (HAT)',
  },
  {
    timeZone: 'America/Anchorage',
    label: 'Alaska Standard Time (AKT) - US & Canada',
  },
  {
    timeZone: 'America/Los_Angeles',
    label: 'Pacific Time (PT) - US & Canada',
  },
  // To reduce number of overload options, we're leaving Phoenix out until we get confirmation that this would be a problem for Arizona users.
  // {timeZone: 'America/Phoenix', label: "Arizona (MST)"},
  {
    timeZone: 'America/Boise',
    label: 'Mountain Time (MT) - US & Canada',
  },
  {
    timeZone: 'America/Chicago',
    label: 'Central Time (CT) - US & Canada',
  },
  {
    timeZone: 'America/Detroit',
    label: 'Eastern Time (ET) - US & Canada',
  },
  {
    timeZone: 'America/Puerto_Rico',
    label: 'Atlantic Standard Time (AST)',
  },
] as const;

export const timezoneMap = {
  'Pacific/Honolulu': 'Pacific/Honolulu',
  'America/Adak': 'Pacific/Honolulu',
  'America/Anchorage': 'America/Anchorage',
  'America/Juneau': 'America/Anchorage',
  'America/Metlakatla': 'America/Anchorage',
  'America/Nome': 'America/Anchorage',
  'America/Sitka': 'America/Anchorage',
  'America/Yakutat': 'America/Anchorage',
  'America/Los_Angeles': 'America/Los_Angeles',
  'America/Phoenix': 'America/Boise',
  'America/Boise': 'America/Boise',
  'America/Denver': 'America/Boise',
  'America/Chicago': 'America/Chicago',
  'America/Indiana/Knox': 'America/Chicago',
  'America/Indiana/Tell_City': 'America/Chicago',
  'America/Menominee': 'America/Chicago',
  'America/North_Dakota/Beulah': 'America/Chicago',
  'America/North_Dakota/Center': 'America/Chicago',
  'America/North_Dakota/New_Salem': 'America/Chicago',
  'America/Detroit': 'America/Detroit',
  'America/Indiana/Indianapolis': 'America/Detroit',
  'America/Indiana/Marengo': 'America/Detroit',
  'America/Indiana/Petersburg': 'America/Detroit',
  'America/Indiana/Vevay': 'America/Detroit',
  'America/Indiana/Vincennes': 'America/Detroit',
  'America/Indiana/Winamac': 'America/Detroit',
  'America/Kentucky/Louisville': 'America/Detroit',
  'America/Kentucky/Monticello': 'America/Detroit',
  'America/New_York': 'America/Detroit',
  'US/Hawaii': 'Pacific/Honolulu',
  'America/Atka': 'Pacific/Honolulu',
  'US/Aleutian': 'Pacific/Honolulu',
  'US/Alaska': 'America/Anchorage',
  'US/Arizona': 'America/Boise',
  'US/Pacific': 'America/Los_Angeles',
  'America/Shiprock': 'America/Boise',
  Navajo: 'America/Boise',
  'US/Mountain': 'America/Boise',
  'America/Knox_IN': 'America/Chicago',
  'US/Central': 'America/Chicago',
  'US/Indiana-Starke': 'America/Chicago',
  'America/Fort_Wayne': 'America/Detroit',
  'America/Indianapolis': 'America/Detroit',
  'America/Louisville': 'America/Detroit',
  'US/East-Indiana': 'America/Detroit',
  'US/Eastern': 'America/Detroit',
  'US/Michigan': 'America/Detroit',
  'America/Dawson': 'America/Boise',
  'America/Dawson_Creek': 'America/Boise',
  'America/Fort_Nelson': 'America/Boise',
  'America/Vancouver': 'America/Los_Angeles',
  'America/Whitehorse': 'America/Boise',
  'America/Cambridge_Bay': 'America/Boise',
  'America/Edmonton': 'America/Boise',
  'America/Inuvik': 'America/Boise',
  'America/Regina': 'America/Chicago',
  'America/Swift_Current': 'America/Chicago',
  'America/Rankin_Inlet': 'America/Chicago',
  'America/Resolute': 'America/Chicago',
  'America/Winnipeg': 'America/Chicago',
  'America/Iqaluit': 'America/Detroit',
  'America/Toronto': 'America/Detroit',
  'Canada/Pacific': 'America/Los_Angeles',
  'Canada/Yukon': 'America/Boise',
  'Canada/Mountain': 'America/Boise',
  'Canada/Saskatchewan': 'America/Chicago',
  'Canada/Central': 'America/Chicago',
  'Canada/Eastern': 'America/Detroit',
  'America/Creston': 'America/Boise',
  'America/Yellowknife': 'America/Boise',
  'America/Atikokan': 'America/Detroit',
  'America/Coral_Harbour': 'America/Detroit',
  'America/Rainy_River': 'America/Chicago',
  'America/Blanc-Sablon': 'America/Puerto_Rico',
  'America/Montreal': 'America/Detroit',
  'America/Nipigon': 'America/Detroit',
  'America/Pangnirtung': 'America/Detroit',
  'America/Thunder_Bay': 'America/Detroit',
  'America/Puerto_Rico': 'America/Puerto_Rico',
} as const;
