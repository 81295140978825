import { Component, Input } from '@angular/core';

import { TripAttributes } from '~app/models/trip';
import { OkayModalComponent } from '~app/components/shared/okay-modal/okay-modal.component';

@Component({
  selector: 'ciao-okay-modal-section-v3',
  standalone: true,
  imports: [OkayModalComponent],
  templateUrl: './okay-modal-section.component.html',
  styleUrl: './okay-modal-section.component.less',
})
export class OkayModalSectionComponent {
  @Input() trip: TripAttributes;
  canOkay(...args) {
    return true;
  }
}
